import Vue from 'vue'
import Vuex from 'vuex'

import router, {Const} from './main'
import axios from "axios";
import jwtService from './mixins/JwtStorageService'

Vue.use(Vuex);

const types = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT'
};

const state = {
    logged: localStorage.getItem('access_token')
};

const getters = {
    isLogged: state => state.logged
};

const actions = {
    login ({commit}, credential) {
        axios.post(Const.API_HOST + '/login', credential)
        .then(function (response) {
            if (response.status === 200) {
                jwtService.setLoginData(
                    response.data.access_token,
                    response.data.refresh_token,
                    response.data.username,
                    response.data.roles)
                commit(types.LOGIN);
                router.push("/")
            }
        }).catch(function (error) {
            // eslint-disable-next-line no-console
            console.log(error)
        })
    },

    logout({commit}) {
        jwtService.removeLoginData()
        commit(types.LOGOUT);
        router.push('/login');
    }
};

const mutations = {
    [types.LOGIN] (state) {
        state.logged = 1;
    },

    [types.LOGOUT] (state) {
        state.logged = 0;
    }
};

const store = new Vuex.Store({
    state,
    getters,
    actions,
    mutations
});

export default store;
