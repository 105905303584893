<template>
    <section class="product-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">Nowy wpis FAQ</h4>
                        <h4 v-else class="card-title">Edytujesz wpis <strong>{{itemName}}</strong> <code>(id: {{item.id}})</code></h4>
                        <p class="card-description pb-4">
                            Uzupełnij poniższe pola
                        </p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-4">
                                    <b-form-group label="Nazwa" label-for="name-input">
                                        <b-form-input type="text" id="name-input" placeholder="Nazwa" v-model="item.name" :state="nameState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group label="Kategoria" label-for="category-input">
                                        <b-form-select v-model="item.category.id" :options="categories" value-field="id" text-field="name" :state="categoryState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group label="Kolejność" label-for="order-input">
                                        <b-form-input type="number" id="order-input" placeholder="Kolejność" v-model="item.itemOrder" :state="orderState" min="1"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2 my-auto">
                                    <b-form-group label="Na stronie głównej" label-for="category-input">
                                        <b-form-checkbox v-model="item.onFrontPage" name="check-button" switch size="lg">
                                            <p v-text="item.onFrontPage ? 'tak' : 'nie'" style="margin-top: 4px;" class="pl-2" />
                                        </b-form-checkbox>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group label="URL strony pytania" label-for="url-input">
                              <b-form-input type="text" id="url-input" placeholder="URL" v-model="item.url" :state="urlState"/>
                            </b-form-group>

                            <b-form-group label="Pytanie" label-for="question-input">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">PL:</div>
                                    <div class="col"><b-form-input type="text" id="question-input" placeholder="Pytanie (PL)" v-model="item.question" :state="questionState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">DE:</div>
                                    <div class="col"><b-form-input type="text" id="questionDe-input" placeholder="Pytanie (DE)" v-model="item.questionDe" :state="questionDeState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">LT:</div>
                                    <div class="col"><b-form-input type="text" id="questionLt-input" placeholder="Pytanie (LT)" v-model="item.questionLt" :state="questionLtState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group label="Odpowiedź (PL)" label-for="answer-input">
                                <b-form-textarea rows="10" id="answer-input" placeholder="Odpowiedź (PL)" v-model="item.answer" :state="answerState"/>
                            </b-form-group>

                            <b-form-group label="Odpowiedź (DE)" label-for="answerDe-input">
                                <b-form-textarea rows="10" id="answerDe-input" placeholder="Odpowiedź (PL)" v-model="item.answerDe" :state="answerDeState"/>
                            </b-form-group>

                            <b-form-group label="Odpowiedź (LT)" label-for="answerLt-input">
                                <b-form-textarea rows="10" id="answerLt-input" placeholder="Odpowiedź (LT)" v-model="item.answerLt" :state="answerLtState"/>
                            </b-form-group>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">Zapisz</b-button>
                            <b-button variant="light" v-on:click="cancel">Anuluj</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>Usuń</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Usuń" @ok="deleteItem">
            <p class="text-center">Potwierdź operację</p>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'FaqItem',
        computed: {
            nameState() {
                if (this.item.name) {
                    return this.item.name.length > 0
                } else {
                    return false
                }
            },
            questionState() {
                if (this.item.question) {
                    return this.item.question.length > 0
                } else {
                    return false
                }
            },
            questionDeState() {
                if (this.item.questionDe) {
                    return this.item.questionDe.length > 0
                } else {
                    return false
                }
            },
            questionLtState() {
                if (this.item.questionLt) {
                    return this.item.questionLt.length > 0
                } else {
                    return false
                }
            },
            answerState() {
                if (this.item.answer) {
                    return this.item.answer.length > 0
                } else {
                    return false
                }
            },
            answerDeState() {
                if (this.item.answerDe) {
                    return this.item.answerDe.length > 0
                } else {
                    return false
                }
            },
            answerLtState() {
                if (this.item.answerLt) {
                    return this.item.answerLt.length > 0
                } else {
                    return false
                }
            },
            orderState() {
                if (this.item.itemOrder) {
                    return this.item.itemOrder > 0
                } else {
                    return false
                }
            },
            categoryState() {
                if (this.item.category) {
                    return this.item.category.id > 0
                } else {
                    return false
                }
            },
            urlState() {
                if (this.item.url) {
                    return this.item.url.length > 0 &&
                        this.item.url.startsWith('/') &&
                        this.item.url.split('/').length === 2
                } else {
                    return false
                }
            },
            validated() {
                return !this.nameState || !this.questionState || !this.questionDeState || !this.questionLtState ||
                    !this.answerState || !this.answerDeState || !this.answerLtState || !this.categoryState ||
                    !this.orderState ||!this.urlState
            }
        },
        data() {
            return {
                categories: [],
                item: {},
                itemName: ''
            }
        },
        mounted() {
            axios.get(Const.API_HOST + '/admin/faqCategories')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.categories = body
            })
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/faqItem/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.itemName = JSON.parse(JSON.stringify(this.item.name))
                })
            } else {
                this.item = {
                    name: '',
                    itemOrder: '',
                    category: {},
                    question: '',
                    questionDe: '',
                    questionLt: '',
                    answer: '',
                    answerDe: '',
                    answerLt: '',
                    onFrontPage: false,
                    url: ''
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/faqs')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/faqItem/save', {
                    faqItem: {
                        id: this.item.id,
                        name: this.item.name,
                        itemOrder: this.item.itemOrder,
                        category: this.item.category,
                        question: this.item.question,
                        questionDe: this.item.questionDe,
                        questionLt: this.item.questionLt,
                        answer: this.item.answer,
                        answerDe: this.item.answerDe,
                        answerLt: this.item.answerLt,
                        onFrontPage: this.item.onFrontPage,
                        url: this.item.url
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/faqs')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/faqItem/delete', {
                    data: {
                        id: this.item.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/faqs')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }
</style>
