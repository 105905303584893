<template lang="html">

  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2023 <a href="http://mtoilet.pl/" target="_blank">mToilet</a>. All rights reserved.</span>
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
      </div>
    </footer>
  </section>

</template>

<script lang="js">
export default {
  name: 'app-footer'
}
</script>

<style scoped lang="scss">
.app-footer {

}
</style>
