<template>
    <section class="tables">
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title">Zapytania o wycenę</h2>
                        <p class="card-description">
                            Zapytania o wyceny skompletowanych koszyków. Zawiera wycenione produkty oraz takie, dla których cena nie jest określone w sklepie mtoilet.pl.
                        </p>
                        <b-table striped hover :items="items" :fields="fields" @row-clicked="openDetails" class="clickable"></b-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <b-pagination
                v-model="pageNr"
                :total-rows="allPages"
                per-page="1"
                first-number
                last-number
                @change="handlePageChange"
                align="right">
            </b-pagination>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    import moment from 'moment'

    export default {
        name: 'OrderRequestList',
        data() {
            return {
                pageNr: 1,
                allPages: "",
                items: [],
                fields: [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true,
                        thStyle: 'width: 75px;'
                    },
                    {
                        key: 'createdDate',
                        label: 'Data zapytania',
                        sortable: true,
                        formatter: value => {
                            return moment(value).format('DD.MM.YYYY, HH:mm');
                        }
                    },
                    {
                        key: 'name',
                        label: 'Imię',
                        sortable: true,
                        formatter: value => {
                            return this.trimEllip(value, 20)
                        }
                    },
                    {
                        key: 'surname',
                        label: 'Nazwisko',
                        sortable: true,
                        formatter: value => {
                            return this.trimEllip(value, 20)
                        }
                    },
                    {
                        key: 'phone',
                        label: 'Telefon kontaktowy',
                        sortable: false
                    },
                    {
                        key: 'email',
                        label: 'Adres email',
                        sortable: true
                    }
                ]
            }
        },
        mounted() {
          this.refreshPage()
        },
        methods: {
            openDetails(item) {
                this.$router.push('/order-request/' + item.id)
            },
            trimEllip(s, length) {
                return s.length > length ? s.substring(0, length) + "..." : s;
            },
            handlePageChange(value) {
                this.pageNr = value;
                this.refreshPage()

            },
            refreshPage() {
                if (this.pageNr == null || this.pageNr == "") {
                    this.pageNr = 1
                }
                axios.get(Const.API_HOST + '/admin/orders/' + this.pageNr)
                    .then(function (response) {
                        return response
                    }).catch(function (error) {
                  // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.items = body.data.orders
                    this.pageNr = body.data.pageNr
                    this.allPages = body.data.allPages
                })
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

</style>
