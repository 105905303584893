<template>
    <section class="product-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Zapytanie o wycenę <code>id: {{item.id}}</code></h4>
                        <p class="card-description pb-4">
                            Szczegóły zapytania
                        </p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h3>Dane klienta</h3>
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th scope="row">Imię</th>
                                            <td>{{item.name}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Nazwisko</th>
                                            <td>{{item.surname}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">NIP</th>
                                            <td>{{item.fiscalNumber}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Nazwa firmy</th>
                                            <td>{{item.companyName}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Telefon kontaktowy</th>
                                            <td>{{item.phone}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Adres email</th>
                                            <td>{{item.email}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Kod pocztowy</th>
                                            <td>{{item.postal}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-lg-6">
                                    <h3>Dane zamówienia</h3>
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th scope="row">Data utworzenia</th>
                                            <td>{{createdDateFormat()}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Uwagi</th>
                                            <td>{{item.extraInfo}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Adres dostawy</th>
                                            <td>{{item.deliveryAddress}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Wynajem od</th>
                                            <td>{{prettyDate(item.deliveryDate)}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Wynajem do</th>
                                            <td>{{prettyDate(item.endDate)}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Telefon dla kierowcy</th>
                                            <td>{{item.contactPhone}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Uwagi dla kierowcy</th>
                                            <td>{{item.deliveryInfo}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Akceptacja regulaminu</th>
                                            <td>{{item.statConsent ? 'tak' : 'nie'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Akceptacja OWU</th>
                                            <td>{{item.owuConsent ? 'tak' : 'nie'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Zgoda RODO</th>
                                            <td>{{item.adConsent ? 'tak' : 'nie'}}</td>
                                        </tr>cd
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col">
                                    <h3>Pozycje w koszyku</h3>
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Produkt</th>
                                            <th scope="col">Serwis</th>
                                            <th scope="col">Ilość</th>
                                            <th scope="col">Cena jednostkowa</th>
                                            <th scope="col">Cena</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(position, index) in cart.positions">
                                            <tr :key="position.id">
                                                <th scope="row">{{index + 1}}</th>
                                                <td v-if="getProductDefWithId(position.id)">{{getProductDefWithId(position.id).name}}</td>
                                                <td>{{getServiceNameWithId(position.variation)}}</td>
                                                <td>{{position.quantity}}</td>
                                                <td>{{getProductPrice(position)}}</td>
                                                <td>{{(position.price * position.quantity).toFixed(2)}}</td>
<!--                                                <td>{{position.selectedAdditionalServices}}</td>-->
                                            </tr>
                                            <tr v-for="(id) in position.selectedAdditionalServices"  :key="id">
                                              <th scope="row"></th>
                                              <td>{{getAdditionalServiceNameWithId(position.id, id)}}</td>
                                              <td></td>
                                              <td>{{getAdditionalServiceType(position.quantity, getAdditionalServiceWithId(position.id, id))}}</td>
                                              <td>{{getAdditionalServiceGrossPrice(position.id, id).toFixed(2)}}</td>
                                              <td>{{calcVasPrice(position.quantity, getAdditionalServiceWithId(position.id, id)).toFixed(2)}}</td>

                                            </tr>
                                        </template>
                                        <tr>
                                            <th scope="row" colspan="5" class="text-right">Cena za pojedyńczy okres najmu</th>
                                            <td class="table-info">{{price.toFixed(2)}}</td>
                                        </tr>
                                        <tr v-if="item.summaryPrice">
                                            <th scope="row" colspan="5" class="text-right">Cena za wybrany okres najmu ({{datePeriod}} dni)</th>
                                            <td class="table-success">{{item.summaryPrice}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <b-button class="mt-5" variant="light" v-on:click="back">Wstecz</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    import moment from 'moment'

    export default {
        name: 'OrderRequest',
        data() {
            return {
                item: {},
                cart: {},
                positionDefinitions: [],
                services: [],
                additionalServices: [],
                price: 0,
                datePeriod: 0
            }
        },
        mounted() {
            axios.get(Const.API_HOST + '/admin/order/get', {
                params: {
                    'id': this.$route.params.id
                }
            }).then(function (response) {
                return response.data
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.item = body
                const calcStartDate = this.parseDate(this.item.deliveryDate)
                const calcEndDate = this.parseDate(this.item.endDate)
                this.datePeriod = this.calculatePeriod(calcStartDate, calcEndDate)

                this.cart = JSON.parse(body.cart)
                for (var position in this.cart.positions) {
                    this.price += this.cart.positions[position].price * this.cart.positions[position].quantity
                    axios.get(Const.API_HOST + '/admin/productItemVas/get', {
                        params: {
                            'id': this.cart.positions[position].id,
                            'vasIds': this.cart.positions[position].selectedAdditionalServices
                        }
                    }).then(function (response) {
                        return response.data
                    }).catch(function (error) {
                        // eslint-disable-next-line no-console
                        console.log(error)
                    }).then(body => {
                        this.positionDefinitions.push(body)
                    })
                }

                for (var cartPos in this.cart.positions) {
                    if (this.cart.positions[cartPos].selectedAdditionalServices !== null) {
                        for (var vasPos in this.cart.positions[cartPos].selectedAdditionalServices) {
                            if (this.positionDefinitions !== null && this.positionDefinitions[cartPos] !== undefined && this.positionDefinitions[cartPos].additionalServices !== null) {
                                for (var vasDefPos in this.positionDefinitions[cartPos].additionalServices) {
                                    if (this.positionDefinitions[cartPos].additionalServices[vasDefPos].id === this.cart.positions[cartPos].selectedAdditionalServices[vasPos]) {
                                        if (this.positionDefinitions[cartPos].additionalServices[vasDefPos].serviceType === "ONE_TIME_FEE") {
                                            this.price += this.positionDefinitions[cartPos].additionalServices[vasDefPos].price * this.cart.positions[cartPos].quantity
                                        } else if (this.positionDefinitions[cartPos].additionalServices[vasDefPos].serviceType === "RECURRING_FEE") {
                                            this.price += this.positionDefinitions[cartPos].additionalServices[vasDefPos].price * 4 * this.cart.positions[cartPos].quantity
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })

            axios.get(Const.API_HOST + '/admin/serviceTypes')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.services = body
            })
        },
        methods: {
            back() {
                if (this.item.paymentOrderId)
                    this.$router.push('/paid-order-requests')
                else
                    this.$router.push('/order-requests')
            },
            getProductDefWithId(id) {
                for (var pos in this.positionDefinitions) {
                    if (this.positionDefinitions[pos].id === id)
                        return this.positionDefinitions[pos]
                }
            },
            getProductPrice(position) {
                if (position.price === null) {
                    return 0
                } else {
                    return position.price.toFixed(2)
                }
            },
            getServiceNameWithId(id) {
                if (id === null || id === 0) {
                    return ""
                }
                for (var pos in this.services) {
                    if (this.services[pos].id === id)
                        return this.services[pos].name
                }
            },
            getAdditionalServiceWithId(id, vasId) {
                for (var pos in this.positionDefinitions) {
                    if (this.positionDefinitions[pos] !== null && this.positionDefinitions[pos].id === id) {
                        for (var vasPos in this.positionDefinitions[pos].additionalServices) {
                            if (this.positionDefinitions[pos].additionalServices[vasPos] !== null
                                && this.positionDefinitions[pos].additionalServices[vasPos].id === vasId) {
                                return this.positionDefinitions[pos].additionalServices[vasPos]
                            }
                        }
                    }
                }
                return null
            },
            getAdditionalServiceNameWithId(id, vasId) {
                let service = this.getAdditionalServiceWithId(id, vasId)
                if (service === null) {
                    return ""
                }
                return service.name
            },
            getAdditionalServiceType(quantity, vas) {
                if (vas === null) {
                    return ""
                } else if (vas.serviceType === "ONE_TIME_FEE") {
                    return quantity
                } else if (vas.serviceType === "RECURRING_FEE") {
                    return quantity.toString() + "x co 1 tydz."
                } else {
                    return ""
                }
            },
            getAdditionalServiceGrossPrice(id, vasId) {
                var vas = this.getAdditionalServiceWithId(id, vasId)
                if (vas === null) {
                    return 0
                } else {
                    return vas.grossPrice
                }
            },
            calcVasPrice(quantity, vas) {
                if (vas === null) {
                    return 0
                }
                if (vas.serviceType === "ONE_TIME_FEE") {
                    return (vas.grossPrice * quantity)
                } else if (vas.serviceType === "RECURRING_FEE") {
                    return (vas.grossPrice * 4 * quantity)
                }
            },
            createdDateFormat() {
                return moment(this.item.createdDate).format('DD.MM.YYYY, HH:mm');
            },
            parseDate(date) {
                if (!date)
                    return

                var dateStr = date.toString().replace(/\D/g,'')
                // eslint-disable-next-line no-console
                console.log(dateStr)
                if (date.toString().startsWith("20")) {
                    return new Date(
                        Number(dateStr.substr(0, 4)),
                        Number(dateStr.substr(4, 2)) - 1,
                        Number(dateStr.substr(6, 2)),
                    )
                } else {
                    return new Date(
                        Number(dateStr.substr(4, 4)),
                        Number(dateStr.substr(2, 2)) - 1,
                        Number(dateStr.substr(0, 2)),
                    )
                }
            },
            calculatePeriod(startDate, endDate) {
                if (!startDate || !endDate)
                    return

                return Math.round((endDate.getTime() - startDate.getTime()) / 86400000)
            },
            prettyDate(date) {
                if (!date)
                    return
                var dateStr = this.parseDate(date)
                return moment(dateStr).locale('pl').format('DD.MM.YYYY (dddd)');
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

    .browser-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    .table td {
        white-space: normal;
    }
</style>
