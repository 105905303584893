<template>
    <section class="page-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">Nowy wpis</h4>
                        <h4 v-else class="card-title">Edytujesz wpis <strong>{{infoName}}</strong> <code>(id:
                            {{info.id}})</code></h4>
                        <p class="card-description pb-4">
                            Uzupełnij poniższe pola
                        </p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group label="Nazwa" label-for="name-input">
                                        <b-form-input type="text" id="name-input" placeholder="Nazwa" v-model="info.name" :state="nameState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-form-group label="Tytuł (wartość 'title' w HEAD strony)" label-for="title-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="title-input" placeholder="Tytuł (PL)" v-model="info.title" :state="titleState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="titleDe-input" placeholder="Tytuł (DE)" v-model="info.titleDe" :state="titleDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="titleLt-input" placeholder="Tytuł (LT)" v-model="info.titleLt" :state="titleLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group label="Główny obraz" label-for="image-input">
                                        <b-button id="image-input" :variant="info.image && info.image.id ? 'outline-success' : 'outline-danger'" @click="openImagePicker('image-input')">
                                            {{info.image && info.image.id ? info.image.filename.substr(43) : 'Wybierz'}}
                                        </b-button>
                                        <b-popover v-if="info.image && info.image.id" target="image-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="info.image.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">Rozmiar: {{info.image.width}} x
                                                {{info.image.height}}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-form-group label="URL podstrony" label-for="url-input">
                                        <b-form-input type="text" id="url-input" placeholder="URL" v-model="info.url" :state="urlState"/>
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-2">
                                    <b-form-group label="Autor" label-for="author-input">
                                        <b-form-input type="text" id="author-input" placeholder="Autor" v-model="info.author" :state="authorState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-10">
                                    <b-form-group label="Opis (wartość 'description' w HEAD strony)" label-for="description-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="description-input" placeholder="Opis (PL)" v-model="info.pageDescription" :state="descriptionState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="descriptionDe-input" placeholder="Opis (DE)" v-model="info.pageDescriptionDe" :state="descriptionDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="descriptionLt-input" placeholder="Opis (LT)" v-model="info.pageDescriptionLt" :state="descriptionLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group label="Zawartość podstrony (PL)" label-for="tinymce-input">
                                <editor ref="formEditor" id="tinymce-input" :api-key="editorApiKey" :init="editorConf" v-model="info.summary"/>
                            </b-form-group>

                            <b-form-group label="Zawartość podstrony (DE)" label-for="tinymce-inputDe">
                                <editor ref="formEditorDe" id="tinymce-inputDe" :api-key="editorApiKey" :init="editorConfDe" v-model="info.summaryDe"/>
                            </b-form-group>

                            <b-form-group label="Zawartość podstrony (LT)" label-for="tinymce-inputLt">
                                <editor ref="formEditorLt" id="tinymce-inputLt" :api-key="editorApiKey" :init="editorConfLt" v-model="info.summaryLt"/>
                            </b-form-group>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">Zapisz</b-button>
                            <b-button variant="light" v-on:click="cancel">Anuluj</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>Usuń</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Usuń" @ok="deleteItem">
            <p class="text-center">Potwierdź operację</p>
        </b-modal>

        <b-modal id="image-picker" title="Wstaw obraz" size="xl" header-bg-variant="primary" header-text-variant="light" okTitle="Zamknij" ok-only>
            <div class="row images">
                <div class="col-lg-1 my-auto text-center p-1" v-for="image in images" :key="image.id" :id="'image-wrapper-' + image.id">
                    <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image" @click="pasteImage(image.id)">
                    <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                        <div class="row">
                            <div class="col text-center">
                                <span><em>{{image.filename.substr(43)}}</em></span><br>
                                <span class="p-0">{{image.width + ' x ' + image.height}}</span>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    var Editor = require('@tinymce/tinymce-vue').default;
    var app = null

    export default {
        name: 'Info',
        components: {
            'editor': Editor
        },
        computed: {
            nameState() {
                if (this.info.name) {
                    return this.info.name.length > 0
                } else {
                    return false
                }
            },
            titleState() {
                if (this.info.title) {
                    return this.info.title.length > 0
                } else {
                    return false
                }
            },
            titleDeState() {
              if (this.info.titleDe) {
                return this.info.titleDe.length > 0
              } else {
                return false
              }
            },
            titleLtState() {
              if (this.info.titleLt) {
                return this.info.titleLt.length > 0
              } else {
                return false
              }
            },
            descriptionState() {
                if (this.info.pageDescription) {
                    return this.info.pageDescription.length > 0
                } else {
                    return false
                }
            },
            descriptionDeState() {
              if (this.info.pageDescriptionDe) {
                return this.info.pageDescriptionDe.length > 0
              } else {
                return false
              }
            },
            descriptionLtState() {
              if (this.info.pageDescriptionLt) {
                return this.info.pageDescriptionLt.length > 0
              } else {
                return false
              }
            },
            urlState() {
                if (this.info.url) {
                    return this.info.url.length > 0 &&
                        this.info.url.startsWith('/') &&
                        this.info.url.split('/').length === 2
                } else {
                    return false
                }
            },
            imageState() {
                return this.info.image && this.info.image.id > 0
            },
            authorState() {
              if (this.info.author) {
                return this.info.author.length > 0
              } else {
                return false
              }
            },
            validated() {
                return !this.nameState || !this.titleState || !this.titleDeState || !this.titleLtState ||
                    !this.descriptionState || !this.descriptionDeState || !this.descriptionLtState || !this.urlState ||
                    !this.imageState || !this.authorState
            }
        },
        data() {
            return {
                images: [],
                info: {},
                infoName: '',
                editorApiKey: "jdsjwlv71ngv4ngm4fca30grz3lu6w833qma1br216ewfsqh",
                editorConf: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-input')
                        });
                    }
                },
                editorConfDe: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-inputDe')
                        });
                    }
                },
                editorConfLt: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-inputLt')
                        });
                    }
                },
                imagePickerContext: ''
            }
        },
        mounted() {
            app = this
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/info/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.info = body
                    this.infoName = JSON.parse(JSON.stringify(this.info.name))
                })
            } else {
                this.info = {
                    name: '',
                    title: '',
                    titleDe: '',
                    titleLt: '',
                    pageDescription: '',
                    pageDescriptionDe: '',
                    pageDescriptionLt: '',
                    image: {},
                    summary: '',
                    summaryDe: '',
                    summaryLt: '',
                    url: '',
                    author: ''
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/infos')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/info/save', {
                    info: {
                        id: this.info.id,
                        name: this.info.name,
                        title: this.info.title,
                        titleDe: this.info.titleDe,
                        titleLt: this.info.titleLt,
                        pageDescription: this.info.pageDescription,
                        pageDescriptionDe: this.info.pageDescriptionDe,
                        pageDescriptionLt: this.info.pageDescriptionLt,
                        image: this.info.image,
                        summary: this.info.summary,
                        summaryDe: this.info.summaryDe,
                        summaryLt: this.info.summaryLt,
                        url: this.info.url,
                        author: this.info.author
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/infos')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/info/delete', {
                    data: {
                        id: this.info.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/infos')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            openImagePicker(context) {
                this.imagePickerContext = context
                this.$bvModal.show('image-picker')
                axios.get(Const.API_HOST + '/admin/images')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.images = body
                })
            },
            pasteImage(id) {
                var selected = this.images.find(obj => {
                    return obj.id === id
                })

                // eslint-disable-next-line no-console
                console.log(selected.filename)
                let html = '<p class="par-with-images"><img src="' + selected.filename + '" alt="' + selected.alt + '" width="' + selected.width + 'px" height="' + selected.height + 'px"></p>';

                if (this.imagePickerContext === 'tinymce-input') {
                    this.$refs.formEditor.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'tinymce-inputDe') {
                    this.$refs.formEditorDe.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'tinymce-inputLt') {
                    this.$refs.formEditorLt.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'image-input') {
                    this.info.image = JSON.parse(JSON.stringify(selected))
                }

                this.$bvModal.hide('image-picker')
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

    .browser-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    /deep/ .tox-editor-container div:first-child {
        top: 58px !important;
    }
</style>
