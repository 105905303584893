// routes.js

import ProductCategoryList from './views/pages/store/ProductCategoryList'
import ServiceTypeList from './views/pages/store/ServiceTypeList'
import ProductItemList from './views/pages/store/ProductItemList'
import ProductItemVariationList from './views/pages/store/ProductItemVariationList'
import ProductCategory from './views/pages/store/ProductCategory'
import ServiceType from './views/pages/store/ServiceType'
import ProductItem from './views/pages/store/ProductItem'
import ImageList from "./views/pages/ImageList";
import FileList from "./views/pages/FileList";
import ProductItemVariation from "./views/pages/store/ProductItemVariation";
import PageList from "./views/pages/cms/PageList";
import Page from "./views/pages/cms/Page";
import FaqCategoryList from "./views/pages/cms/FaqCategoryList";
import FaqCategory from "./views/pages/cms/FaqCategory";
import FaqItemList from "./views/pages/cms/FaqItemList";
import FaqItem from "./views/pages/cms/FaqItem";
import DepartmentList from "./views/pages/cms/DepartmentList";
import Department from "./views/pages/cms/Department";
import FragmentList from "./views/pages/cms/FragmentList";
import Fragment from "./views/pages/cms/Fragment";
import InfoList from "./views/pages/cms/InfoList";
import Info from "./views/pages/cms/Info";
import Login from "./views/pages/admin/Login";
import OrderRequestList from "./views/pages/store/OrderRequestList";
import OrderRequest from "./views/pages/store/OrderRequest";
import OrderRequestPayedList from "./views/pages/store/OrderRequestPayedList";

const routes = [
    // {
    //   path: '/',
    //   name: 'dashboard',
    //   component: dashboard
    // },
    {
        path: '/',
        name: 'dashboard',
        component: OrderRequestList,
        props: {
            limit: 10
        }
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/product-categories',
        component: ProductCategoryList
    },
    {
        path: '/product-category/edit/:id',
        component: ProductCategory,
        props: {
            mode: 'edit'
        }
    },
    {
        path: '/product-category/new',
        component: ProductCategory,
        props: {
            mode: 'new'
        }
    },
    {
        path: '/service-types',
        component: ServiceTypeList
    },
    {
        path: '/service-type/edit/:id',
        component: ServiceType,
        props: {
            mode: 'edit'
        }
    },
    {
        path: '/service-type/new',
        component: ServiceType,
        props: {
            mode: 'new'
        }
    },
    {
        path: '/products',
        component: ProductItemList
    },
    {
        path: '/product/edit/:id',
        component: ProductItem,
        props: {
            mode: 'edit'
        }
    },
    {
        path: '/product/new',
        component: ProductItem,
        props: {
            mode: 'new'
        }
    },
    {
        path: '/prices',
        component: ProductItemVariationList
    },
    {
        path: '/price/edit/:id',
        component: ProductItemVariation,
        props: {
            mode: 'edit'
        }
    },
    {
        path: '/price/new',
        component: ProductItemVariation,
        props: {
            mode: 'new'
        }
    },
    {
        path: '/images',
        component: ImageList
    },
    {
        path: '/files',
        component: FileList
    },
    {
        path: '/pages',
        component: PageList
    },
    {
        path: '/page/edit/:id',
        component: Page,
        props: {
            mode: 'edit'
        }
    },
    {
        path: '/page/new',
        component: Page,
        props: {
            mode: 'new'
        }
    },
    {
        path: '/faq-categories',
        component: FaqCategoryList
    },
    {
        path: '/faq-category/edit/:id',
        component: FaqCategory,
        props: {
            mode: 'edit'
        }
    },
    {
        path: '/faq-category/new',
        component: FaqCategory,
        props: {
            mode: 'new'
        }
    },
    {
        path: '/faqs',
        component: FaqItemList
    },
    {
        path: '/faq/edit/:id',
        component: FaqItem,
        props: {
            mode: 'edit'
        }
    },
    {
        path: '/faq/new',
        component: FaqItem,
        props: {
            mode: 'new'
        }
    },
    {
        path: '/departments',
        component: DepartmentList
    },
    {
        path: '/department/edit/:id',
        component: Department,
        props: {
            mode: 'edit'
        }
    },
    {
        path: '/department/new',
        component: Department,
        props: {
            mode: 'new'
        }
    },
    {
        path: '/fragments',
        component: FragmentList
    },
    {
        path: '/fragment/edit/:id',
        component: Fragment,
        props: {
            mode: 'edit'
        }
    },
    {
        path: '/infos',
        component: InfoList
    },
    {
        path: '/info/edit/:id',
        component: Info,
        props: {
            mode: 'edit'
        }
    },
    {
        path: '/info/new',
        component: Info,
        props: {
            mode: 'new'
        }
    },
    {
        path: '/order-requests',
        component: OrderRequestList
    },
    {
        path: '/paid-order-requests',
        component: OrderRequestPayedList
    },
    {
        path: '/order-request/:id',
        component: OrderRequest
    },
]

export default routes;