<template>
    <section class="images">
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body" :class="{'drop-active': $refs.upload && $refs.upload.dropActive}">
                        <div class="row">
                            <div class="col">
                                <div id="upload">
                                    <div class="upload">
                                        <div v-if="files.length">
                                            <table role="table" aria-busy="false" aria-colcount="3" class="table b-table table-striped table-hover">

                                                <thead role="rowgroup" class="">
                                                    <tr role="row" class="">
                                                        <th role="columnheader" scope="col" aria-colindex="1" class="">Nazwa pliku</th>
                                                        <th role="columnheader" scope="col" aria-colindex="2" class="" style="width: 100px;">Rozmiar</th>
                                                        <th role="columnheader" scope="col" aria-colindex="3" class="text-center" style="width: 150px;">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody role="rowgroup">
                                                    <tr v-for="file in files" :key="file.id">
                                                        <td aria-colindex="1" role="cell" class="" v-text="file.name"/>
                                                        <td aria-colindex="2" role="cell" class="" v-text="humanFileSize(file.size)"/>
                                                        <td aria-colindex="3" role="cell" class="text-center">
                                                            <b-button v-if="file.error" squared variant="danger" disabled>{{data.error}}</b-button>
                                                            <b-button v-else-if="file.success" squared variant="success" disabled>ok</b-button>
                                                            <b-button v-else-if="file.active" squared variant="warning" disabled>przesyłam</b-button>
                                                            <b-button v-else squared disabled>czeka</b-button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-else class="text-center p-5">
                                            <h4>Przeciągnij obrazki tutaj <br/>lub</h4>
                                            <label for="file" class="btn btn-lg btn-primary">Wybierz z dysku</label>
                                        </div>

                                        <div class="upload-btn text-right pt-3">
                                            <file-upload class="btn btn-primary p-3 ml-2 mr-2" :post-action="uploadUrl" :multiple="true" :headers="authHeader"
                                                         @input-file="inputFile" @input-filter="inputFilter"
                                                         :drop="true" v-model="files" ref="upload">
                                                <i class="fa fa-plus"></i>
                                                Dodaj z dysku
                                            </file-upload>
                                            <button type="button" class="btn btn-success p-3 ml-2 mr-2" v-if="!$refs.upload || !$refs.upload.active"
                                                    @click.prevent="$refs.upload.active = true">
                                                Ropocznij upload
                                            </button>
                                            <button type="button" class="btn btn-danger p-3 ml-2 mr-2" v-else @click.prevent="$refs.upload.active = false">
                                                Zatrzymaj upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4>Galeria</h4>
                        <div class="row images">
                            <div class="col-lg-2 my-auto text-center p-2" v-for="image in items" :key="image.id" :id="'image-wrapper-' + image.id">
                                <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image" @click="openEditModal">
                                <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                                    <div class="row">
                                        <div class="col text-center">
                                            <span><em>{{image.filename.substr(43)}}</em></span><br>
                                            <span class="p-0">{{image.width + ' x ' + image.height}}</span>
                                        </div>
                                    </div>
                                </b-popover>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="edit-img-modal" ref="modal" title="Edycja obrazu" cancelTitle="Anuluj" okTitle="Zapisz"
                 @ok="editImage" size="lg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 my-auto text-center">
                        <img :src="editedItem.filename" class="browser-image">
                    </div>
                    <div class="col-lg-8">
                        <b-form-group label="Nazwa pliku" label-for="name-input">
                            <b-form-input v-model="formatFilename" type="text" id="name-input" disabled/>
                            <i class="browser-image-icon icon mdi mdi-delete-forever" @click="deleteImage"></i>
                        </b-form-group>
                        <b-form-group label="Wymiary" label-for="name-input">
                            <b-form-input v-model="formatSize" type="text" id="name-input" disabled/>
                        </b-form-group>
                        <b-form-group label="Tekst alternatywny" label-for="alt-input">
                            <b-form-input v-model="editedItem.alt" type="text" id="alt-input"
                                          placeholder="Tekst alternatywny"/>
                        </b-form-group>
                    </div>
                </div>
            </div>

        </b-modal>
    </section>
</template>

<script>
    import axios from "axios";
    import {Const} from "../../main";
    import jwtService from "../../mixins/JwtStorageService";

    export default {
        name: "ImageList",
        computed: {
            formatFilename() {
                if (this.editedItem.filename)
                    return this.editedItem.filename.substr(43)

                return ''
            },
            formatSize() {
                if (this.editedItem.width && this.editedItem.height)
                    return this.editedItem.width + ' x ' + this.editedItem.height

                return ''
            }
        },
        data() {
            return {
                items: [],
                editedItem: {},
                files: [],
                uploadUrl: Const.API_HOST + '/admin/image/upload',
                fields: [
                    {
                        key: 'file.name',
                        label: 'Nazwa pliku',
                    },
                    {
                        key: 'file.size',
                        label: 'Rozmiar',
                        formatter: value => {
                            return this.humanFileSize(value)
                        }
                    },
                    {
                        key: 'file',
                        label: 'Status',
                        thStyle: 'width: 75px;'
                    }
                ],
                authHeader: {}
            }
        },
        mounted() {
            axios.get(Const.API_HOST + '/admin/images')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.items = body
            })

            const token = jwtService.getAccessToken();
            if (token) {
                this.authHeader = {'Authorization': 'Bearer ' + token}
            }
        },
        methods: {
            openEditModal(item) {
                var id = parseInt(item.target.id)
                this.editedItem = this.items.find(obj => {
                    return obj.id === id
                })
                this.$bvModal.show('edit-img-modal')
            },
            editImage() {
                axios.post(Const.API_HOST + '/admin/image/edit', {
                    id: this.editedItem.id,
                    alt: this.editedItem.alt
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
                this.editedItem = {}
            },
            deleteImage() {
              axios.post(Const.API_HOST + '/admin/image/remove', {
                id: this.editedItem.id
              }).then(function (response) {
                // eslint-disable-next-line no-console
                console.log(response)
                window.location.reload()
              }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
              })
              this.editedItem = {}
              this.$bvModal.hide('edit-img-modal')
            },
            inputFile: function (newFile, oldFile) {
                if (newFile && oldFile && !newFile.active && oldFile.active) {
                    // Get response data
                    // eslint-disable-next-line no-console
                    console.log('response', newFile.response)
                    if (newFile.xhr) {
                        //  Get the response status code
                        // eslint-disable-next-line no-console
                        console.log('status', newFile.xhr.status)
                    }
                }
            },
            inputFilter: function (newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    // Filter non-image file
                    if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
                        return prevent()
                    }
                }

                // Create a blob field
                newFile.blob = ''
                let URL = window.URL || window.webkitURL
                if (URL && URL.createObjectURL) {
                    newFile.blob = URL.createObjectURL(newFile.file)
                }
            },
            humanFileSize (size) {
                var i = Math.floor( Math.log(size) / Math.log(1024) );
                return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
            }
        }
    }
</script>

<style scoped>
    .browser-image {
        max-width: 100%;
        max-height: 100%;
    }

    .browser-image-icon {
        position: absolute;
        font-size: 16pt;
        color: #dc1212;
        top: 17pt;
        right: 15pt;
        cursor: pointer;
    }

    .drop-active::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: blue;
        opacity: 0.05;
    }
</style>