<template>
    <section class="faq-category">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">Nowa kategoria FAQ</h4>
                        <h4 v-else class="card-title">Edytujesz kategorię <strong>{{itemName}}</strong> <code>(id: {{item.id}})</code></h4>
                        <p class="card-description pb-4">
                            Uzupełnij poniższe pola
                        </p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group label="Nazwa" label-for="name-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="name-input" placeholder="Nazwa (PL)" v-model="item.name" :state="nameState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="nameDe-input" placeholder="Nazwa (DE)" v-model="item.nameDe" :state="nameDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="nameLt-input" placeholder="Nazwa (LT)" v-model="item.nameLt" :state="nameLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-form-group label="Kolejność" label-for="order-input">
                                        <b-form-input type="number" id="order-input" placeholder="Kolejność" v-model="item.itemOrder" :state="orderState" min="1"/>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group label="Miniatura" label-for="thumbnail-input">
                                        <b-button id="thumbnail-input" :variant="item.thumbnail && item.thumbnail.id ? 'outline-success' : 'outline-danger'" @click="openImagePicker('thumbnail-input')">
                                            {{ item.thumbnail && item.thumbnail.id ? item.thumbnail.filename.substr(43) : 'Wybierz' }}
                                        </b-button>
                                        <b-popover v-if="item.thumbnail && item.thumbnail.id" target="thumbnail-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="item.thumbnail.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">Rozmiar: {{ item.thumbnail.width }} x {{ item.thumbnail.height }}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">Zapisz</b-button>
                            <b-button variant="light" v-on:click="cancel">Anuluj</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>Usuń</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Usuń" @ok="deleteItem">
            <p class="text-center">Twoja zmiana będzie miała duży wpływ i wygląd strony. Wraz z kategorią usunięte
                zostaną wszystkie wpisy FAQ z tej kategorii. Aby kontynuować zatwierdź zmianę przyciskiem
                <strong>Usuń</strong></p>
        </b-modal>
        <b-modal id="image-picker" title="Wstaw obraz" size="xl" header-bg-variant="primary" header-text-variant="light" okTitle="Zamknij" ok-only>
            <div class="row images">
                <div class="col-lg-1 my-auto text-center p-1" v-for="image in images" :key="image.id" :id="'image-wrapper-' + image.id">
                    <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image" @click="pasteImage(image.id)">
                    <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                        <div class="row">
                            <div class="col text-center">
                                <span><em>{{ image.filename.substr(43) }}</em></span><br>
                                <span class="p-0">{{ image.width + ' x ' + image.height }}</span>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>
        </b-modal>

    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'FaqCategory',
        computed: {
            nameState() {
                if (this.item.name) {
                    return this.item.name.length > 0
                } else {
                    return false
                }
            },
            nameDeState() {
                if (this.item.nameDe) {
                    return this.item.nameDe.length > 0
                } else {
                    return false
                }
            },
            nameLtState() {
                if (this.item.nameLt) {
                    return this.item.nameLt.length > 0
                } else {
                    return false
                }
            },
            orderState() {
                if (this.item.itemOrder) {
                    return this.item.itemOrder > 0
                } else {
                    return false
                }
            },
            thumbnailState() {
                return this.item.thumbnail && this.item.thumbnail.id > 0
            },
            validated() {
              return !this.nameState || !this.nameDeState || !this.orderState || !this.thumbnailState
            }
        },
        data() {
            return {
                item: {},
                itemName: '',
                imagePickerContext: '',
                images: []
            }
        },
        mounted() {
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/faqCategory/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.itemName = JSON.parse(JSON.stringify(this.item.name))
                })
            } else {
                this.item = {
                    name: '',
                    nameDe: '',
                    nameLt: '',
                    itemOrder: '',
                    thumbnail: {}
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/faq-categories')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/faqCategory/save', {
                    id: this.item.id,
                    name: this.item.name,
                    nameDe: this.item.nameDe,
                    nameLt: this.item.nameLt,
                    itemOrder: this.item.itemOrder,
                    thumbnail: this.item.thumbnail.id
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/faq-categories')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/faqCategory/delete', {
                    data: {
                        id: this.item.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/faq-categories')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            openImagePicker(context) {
                this.imagePickerContext = context
                this.$bvModal.show('image-picker')
                axios.get(Const.API_HOST + '/admin/images')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                        // eslint-disable-next-line no-console
                        console.log(error)
                    }).then(body => {
                        this.images = body
                    })
            },
            pasteImage(id) {
                var selected = this.images.find(obj => {
                    return obj.id === id
                })
                this.item.thumbnail = JSON.parse(JSON.stringify(selected))
                this.$bvModal.hide('image-picker')
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

    .browser-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

</style>
