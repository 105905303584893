<template>
    <section class="tables">
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title">Fragmenty stron</h2>
                        <p class="card-description">
                            Statyczne fragmenty stron. Modyfikuj te wpisy tylko wtedy, gdy wiesz co robisz.
                        </p>
                        <!--<b-button variant="primary" class="mt-4 mb-4" @click="$router.push('/faq-category/new')">Nowy fragment</b-button>-->
                        <b-table striped hover :items="items" :fields="fields" @row-clicked="editFragment" class="clickable"></b-table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'FragmentList',
        data() {
            return {
                items: [],
                fields: [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true,
                        thStyle: 'width: 75px;'
                    },
                    {
                        key: 'name',
                        label: 'Nazwa',
                        sortable: true
                    },
                    {
                        key: 'key',
                        label: 'Identyfikator techniczny',
                        sortable: true
                    }
                ]
            }
        },
        mounted() {
            axios.get(Const.API_HOST + '/admin/fragments')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.items = body
            })
        },
        methods: {
            editFragment(item) {
                // eslint-disable-next-line no-console
                console.log(item)
                this.$router.push('/fragment/edit/' + item.id)
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

</style>
