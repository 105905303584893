<template>
    <section class="department-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">Nowy oddział</h4>
                        <h4 v-else class="card-title">Edytujesz oddział <strong>{{departmentName}}</strong> <code>(id:
                            {{department.id}})</code></h4>
                        <p class="card-description pb-4">
                            Uzupełnij poniższe pola
                        </p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-4">
                                    <b-form-group label="Nazwa" label-for="name-input">
                                        <b-form-input type="text" id="name-input" placeholder="Nazwa" v-model="department.name" :state="nameState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group label="Tytuł (wartość 'title' w HEAD strony)" label-for="title-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="title-input" placeholder="Tytuł (PL)" v-model="department.title" :state="titleState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="titleDe-input" placeholder="Tytuł (DE)" v-model="department.titleDe" :state="titleDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="titleLt-input" placeholder="Tytuł (LT)" v-model="department.titleLt" :state="titleLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group label="URL strony" label-for="url-input">
                                        <b-form-input type="text" id="url-input" placeholder="URL" v-model="department.url" :state="urlState"/>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <b-form-group label="Opis (wartość 'description' w HEAD strony)" label-for="desc-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="desc-input" placeholder="Opis (PL)" v-model="department.description" :state="descriptionState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="descDe-input" placeholder="Opis (DE)" v-model="department.descriptionDe" :state="descriptionDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="descLt-input" placeholder="Opis (LT)" v-model="department.descriptionLt" :state="descriptionLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-2">
                                    <b-form-group label="Kod pocztowy" label-for="postal-input">
                                        <b-form-input type="text" id="postal-input" placeholder="Kod pocztowy" v-model="department.postal"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group label="Miasto" label-for="city-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="city-input" placeholder="Miasto (PL)" v-model="department.city" :state="cityState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="cityDe-input" placeholder="Miasto (DE)" v-model="department.cityDe" :state="cityDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="cityLt-input" placeholder="Miasto (LT)" v-model="department.cityLt" :state="cityLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group label="Adres" label-for="address-input">
                                        <b-form-input type="text" id="address-input" placeholder="Adres" v-model="department.address" :state="addressState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group label="Adres (druga linia)" label-for="address2-input">
                                        <b-form-input type="text" id="address2-input" placeholder="Adres cd." v-model="department.addressNextLine"/>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3">
                                    <b-form-group label="Czynne" label-for="open-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="open-input" placeholder="Czynne (PL)" v-model="department.open"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="openDe-input" placeholder="Czynne (DE)" v-model="department.openDe"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="openLt-input" placeholder="Czynne (LT)" v-model="department.openLt"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-3">
                                    <b-form-group label="Adres email" label-for="email-input">
                                        <b-form-input type="text" id="email-input" placeholder="Email" v-model="department.email"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group label="Szerokość geo." label-for="xcor-input">
                                        <b-form-input type="number" id="xcor-input" placeholder="Szerokość" v-model="department.xcoordinate" :state="xState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group label="Długość geo." label-for="ycor-input">
                                        <b-form-input type="number" step="any" id="ycor-input" placeholder="Długość" v-model="department.ycoordinate" :state="yState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group label="Kolejność" label-for="order-input">
                                        <b-form-input type="number" step="any" id="order-input" placeholder="Kolejność" v-model="department.itemOrder" :state="orderState" min="1"/>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group label="Opis oddziału (PL)" label-for="tinymce-input">
                                <editor ref="formEditor" id="tinymce-input" :api-key="editorApiKey" :init="editorConf" v-model="department.summary"/>
                            </b-form-group>

                            <b-form-group label="Opis oddziału (DE)" label-for="tinymce-inputDe">
                                <editor ref="formEditorDe" id="tinymce-inputDe" :api-key="editorApiKey" :init="editorConfDe" v-model="department.summaryDe"/>
                            </b-form-group>

                            <b-form-group label="Opis oddziału (LT)" label-for="tinymce-inputLt">
                                <editor ref="formEditorLt" id="tinymce-inputLt" :api-key="editorApiKey" :init="editorConfLt" v-model="department.summaryLt"/>
                            </b-form-group>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">Zapisz</b-button>
                            <b-button variant="light" v-on:click="cancel">Anuluj</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>Usuń</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Usuń" @ok="deleteItem">
            <p class="text-center">Potwierdź operację</p>
        </b-modal>

        <b-modal id="image-picker" title="Wstaw obraz" size="xl" header-bg-variant="primary" header-text-variant="light" okTitle="Zamknij" ok-only>
            <div class="row images">
                <div class="col-lg-1 my-auto text-center p-1" v-for="image in images" :key="image.id" :id="'image-wrapper-' + image.id">
                    <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image" @click="pasteImage(image.id)">
                    <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                        <div class="row">
                            <div class="col text-center">
                                <span><em>{{image.filename.substr(43)}}</em></span><br>
                                <span class="p-0">{{image.width + ' x ' + image.height}}</span>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    var Editor = require('@tinymce/tinymce-vue').default;
    var app = null

    export default {
        name: 'Department',
        components: {
            'editor': Editor
        },
        computed: {
            nameState() {
                if (this.department.name) {
                    return this.department.name.length > 0
                } else {
                    return false
                }
            },
            titleState() {
                if (this.department.title) {
                    return this.department.title.length > 0
                } else {
                    return false
                }
            },
            titleDeState() {
                if (this.department.titleDe) {
                    return this.department.titleDe.length > 0
                } else {
                    return false
                }
            },
            titleLtState() {
                if (this.department.titleLt) {
                    return this.department.titleLt.length > 0
                } else {
                    return false
                }
            },
            descriptionState() {
                if (this.department.description) {
                    return this.department.description.length > 0
                } else {
                    return false
                }
            },
            descriptionDeState() {
                if (this.department.descriptionDe) {
                    return this.department.descriptionDe.length > 0
                } else {
                    return false
                }
            },
            descriptionLtState() {
                if (this.department.descriptionLt) {
                    return this.department.descriptionLt.length > 0
                } else {
                    return false
                }
            },
            urlState() {
                if (this.department.url) {
                    return this.department.url.length > 0 &&
                        this.department.url.startsWith('/') &&
                        this.department.url.split('/').length === 2
                } else {
                    return false
                }
            },
            cityState() {
                if (this.department.city) {
                    return this.department.city.length > 0
                } else {
                    return false
                }
            },
            cityDeState() {
                if (this.department.cityDe) {
                    return this.department.cityDe.length > 0
                } else {
                    return false
                }
            },
            cityLtState() {
                if (this.department.cityLt) {
                    return this.department.cityLt.length > 0
                } else {
                    return false
                }
            },
            addressState() {
                if (this.department.address) {
                    return this.department.address.length > 0
                } else {
                    return false
                }
            },
            xState() {
                if (this.department.xcoordinate) {
                    return this.department.xcoordinate > 0
                } else {
                    return false
                }
            },
            yState() {
                if (this.department.ycoordinate) {
                    return this.department.ycoordinate > 0
                } else {
                    return false
                }
            },
            orderState() {
                if (this.department.itemOrder) {
                    return this.department.itemOrder > 0
                } else {
                    return false
                }
            },
            validated() {
                return !this.nameState || !this.titleState || !this.titleDeState || !this.titleLtState ||
                    !this.urlState || !this.cityState || !this.cityDeState || !this.cityLtState || !this.addressState ||
                    !this.xState || !this.yState || !this.orderState
            }
        },
        data() {
            return {
                images: [],
                department: {},
                departmentName: '',
                editorApiKey: "jdsjwlv71ngv4ngm4fca30grz3lu6w833qma1br216ewfsqh",
                editorConf: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-input')
                        });
                    }
                },
                editorConfDe: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-inputDe')
                        });
                    }
                },
                editorConfLt: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-inputLt')
                        });
                    }
                },
                imagePickerContext: ''
            }
        },
        mounted() {
            app = this
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/department/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.department = body
                    this.departmentName = JSON.parse(JSON.stringify(this.department.name))
                })
            } else {
                this.department = {
                    name: '',
                    title: '',
                    titleDe: '',
                    titleLt: '',
                    description: '',
                    descriptionDe: '',
                    descriptionLt: '',
                    city: '',
                    cityDe: '',
                    cityLt: '',
                    address: '',
                    addressNextLine: '',
                    postal: '',
                    email: '',
                    open: '',
                    openDe: '',
                    openLt: '',
                    summary: '',
                    summaryDe: '',
                    summaryLt: '',
                    xcoordinate: '',
                    ycoordinate: '',
                    url: '',
                    itemOrder: ''
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/departments')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/department/save', {
                    department: {
                        id: this.department.id,
                        name: this.department.name,
                        title: this.department.title,
                        titleDe: this.department.titleDe,
                        titleLt: this.department.titleLt,
                        description: this.department.description,
                        descriptionDe: this.department.descriptionDe,
                        descriptionLt: this.department.descriptionLt,
                        city: this.department.city,
                        cityDe: this.department.cityDe,
                        cityLt: this.department.cityLt,
                        address: this.department.address,
                        addressNextLine: this.department.addressNextLine,
                        postal: this.department.postal,
                        email: this.department.email,
                        open: this.department.open,
                        openDe: this.department.openDe,
                        openLt: this.department.openLt,
                        summary: this.department.summary,
                        summaryDe: this.department.summaryDe,
                        summaryLt: this.department.summaryLt,
                        xcoordinate: this.department.xcoordinate,
                        ycoordinate: this.department.ycoordinate,
                        url: this.department.url,
                        itemOrder: this.department.itemOrder
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/departments')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/department/delete', {
                    data: {
                        id: this.department.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/departments')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            openImagePicker(context) {
                this.imagePickerContext = context
                this.$bvModal.show('image-picker')
                axios.get(Const.API_HOST + '/admin/images')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.images = body
                })
            },
            pasteImage(id) {
                var selected = this.images.find(obj => {
                    return obj.id === id
                })

                // eslint-disable-next-line no-console
                console.log(selected.filename)
                var html = '<p class="par-with-images"><img src="' + selected.filename + '" alt="' + selected.alt + '"></p>'

                if (this.imagePickerContext === 'tinymce-input') {
                    this.$refs.formEditor.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'tinymce-inputDe') {
                    this.$refs.formEditorDe.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'tinymce-inputLt') {
                    this.$refs.formEditorLt.editor.execCommand('mceInsertContent', false, html);
                }

                this.$bvModal.hide('image-picker')
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

    .browser-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    /deep/ .tox-editor-container div:first-child {
        top: 58px !important;
    }
</style>
