<template>
    <section class="fragment">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">Nowy fragment strony</h4>
                        <h4 v-else class="card-title">Edytujesz fragment <strong>{{itemName}}</strong> <code>(id: {{item.id}})</code></h4>
                        <p class="card-description pb-4">
                            Uzupełnij poniższe pola
                        </p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group label="Nazwa" label-for="name-input">
                                        <b-form-input type="text" id="name-input" placeholder="Nazwa" v-model="item.name" disabled/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-form-group label="Identyfikator techniczny" label-for="key-input">
                                        <b-form-input type="text" id="key-input" placeholder="Identyfikator" v-model="item.key" disabled/>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group label="Zawartość (PL)" label-for="tinymce-input">
                                <editor ref="formEditor" id="tinymce-input" :api-key="editorApiKey" :init="editorConf" v-model="item.value"/>
                            </b-form-group>

                            <b-form-group label="Zawartość (DE)" label-for="tinymce-inputDe">
                                <editor ref="formEditorDe" id="tinymce-inputDe" :api-key="editorApiKey" :init="editorConfDe" v-model="item.valueDe"/>
                            </b-form-group>

                            <b-form-group label="Zawartość (LT)" label-for="tinymce-inputLt">
                                <editor ref="formEditorLt" id="tinymce-inputLt" :api-key="editorApiKey" :init="editorConfLt" v-model="item.valueLt"/>
                            </b-form-group>

                            <b-button variant="success" class="mr-2" @click="saveItem">Zapisz</b-button>
                            <b-button variant="light" v-on:click="cancel">Anuluj</b-button>
                            <!--<b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>Usuń</b-button>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<b-modal id="confirm-delete" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Usuń" @ok="deleteItem">
            <p class="text-center">Twoja zmiana będzie miała duży wpływ i wygląd strony. Wraz z kategorią usunięte
                zostaną wszystkie wpisy FAQ z tej kategorii. Aby kontynuować zatwierdź zmianę przyciskiem
                <strong>Usuń</strong></p>
        </b-modal>-->
        <b-modal id="image-picker" title="Wstaw obraz" size="xl" header-bg-variant="primary" header-text-variant="light" okTitle="Zamknij" ok-only>
            <div class="row images">
                <div class="col-lg-1 my-auto text-center p-1" v-for="image in images" :key="image.id" :id="'image-wrapper-' + image.id">
                    <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image" @click="pasteImage(image.id)">
                    <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                        <div class="row">
                            <div class="col text-center">
                                <span><em>{{image.filename.substr(43)}}</em></span><br>
                                <span class="p-0">{{image.width + ' x ' + image.height}}</span>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    var Editor = require('@tinymce/tinymce-vue').default;
    var app = null

    export default {
        name: 'Fragment',
        components: {
            'editor': Editor
        },
        data() {
            return {
                item: {},
                itemName: '',
                images: [],
                editorApiKey: "jdsjwlv71ngv4ngm4fca30grz3lu6w833qma1br216ewfsqh",
                editorConf: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-input')
                        });
                    }
                },
                editorConfDe: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-inputDe')
                        });
                    }
                },
                editorConfLt: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-inputLt')
                        });
                    }
                },
                imagePickerContext: ''
            }
        },
        mounted() {
            app = this
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/fragment/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.itemName = JSON.parse(JSON.stringify(this.item.name))
                })
            } else {
                this.item = {
                    name: '',
                    key: '',
                    value: '',
                    valueDe: '',
                    valueLt: ''
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/fragments')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/fragment/save', {
                    id: this.item.id,
                    name: this.item.name,
                    key: this.item.key,
                    value: this.item.value,
                    valueDe: this.item.valueDe,
                    valueLt: this.item.valueLt
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/fragments')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            openImagePicker(context) {
                this.imagePickerContext = context
                this.$bvModal.show('image-picker')
                axios.get(Const.API_HOST + '/admin/images')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.images = body
                })
            },
            pasteImage(id) {
                var selected = this.images.find(obj => {
                    return obj.id === id
                })

                // eslint-disable-next-line no-console
                console.log(selected.filename)
                var html = '<p class="par-with-images"><img src="' + selected.filename + '" alt="' + selected.alt + '" width="' + selected.width + 'px" height="' + selected.height + 'px"></p>'

                if (this.imagePickerContext === 'tinymce-input') {
                    this.$refs.formEditor.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'tinymce-inputDe') {
                    this.$refs.formEditorDe.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'tinymce-inputLt') {
                    this.$refs.formEditorLt.editor.execCommand('mceInsertContent', false, html);
                }

                this.$bvModal.hide('image-picker')
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

    .browser-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    /deep/ .tox-editor-container div:first-child {
        top: 58px !important;
    }
</style>
