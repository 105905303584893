<template lang="html">

  <section class="login">
    <div class="wrapper d-flex align-items-center auth login-full-bg">
      <div class="row w-100">
        <div class="col-lg-6 mx-auto">
          <div class="auth-form-dark text-left p-5">
            <h2>Zaloguj</h2>
            <h4 class="font-weight-light">Aby się zalogować proszę podać swój login i hasło.</h4>
            <form class="pt-5">
              <form>
                <div class="form-group">
                  <label for="inputUsername">Email</label>
                  <input type="text" v-model="credential.username" class="form-control" id="inputUsername" aria-describedby="emailHelp" placeholder="Email">
                  <i class="mdi mdi-account"></i>
                </div>
                <div class="form-group">
                  <label for="inputPassword">Hasło</label>
                  <input type="password" v-model="credential.password" class="form-control" id="inputPassword" placeholder="Hasło">
                  <i class="mdi mdi-eye"></i>
                </div>
                <div class="mt-5">
                  <a v-on:click="login" class="btn btn-block btn-warning btn-lg font-weight-medium" to="/">Zaloguj</a>
                </div>
                <!--<div class="mt-3 text-center">
                  <a href="#" class="auth-link text-white">Forgot password?</a>
                </div>-->
              </form>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script lang="js">
import {mapGetters} from "vuex";

export default {
  name: 'Login',
  computed: mapGetters([
    'isLogged'
  ]),
  data() {
    return {
      credential: {
        username:'',
        password:''
      }
    }
  },
  mounted() {
    if (this.isLogged)
      this.$router.push("/")
    else
      this.$router.push("/login")
  },
  methods: {
    login() {
      this.$store.dispatch('login', this.credential);
    }
  },
}
</script>

<style scoped lang="scss">
  .login, .wrapper {
    min-height: 100vh;
  }
</style>
