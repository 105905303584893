import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import axios from 'axios'
import jwtService from './mixins/JwtStorageService'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Viewer from 'v-viewer'
import routes from './routes';

import 'jquery/src/jquery.js'

import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'viewerjs/dist/viewer.css'

const VueUploadComponent = require('vue-upload-component')

Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(Viewer)
Vue.component('file-upload', VueUploadComponent)

export const Const = Object.freeze({
    API_HOST: process.env.VUE_APP_API_HOST_URL,
    WEB_HOST: process.env.VUE_APP_WEB_PAGE_HOST_URL,
    MODE_NEW: 'new',
    MODE_EDIT: 'edit'
})

Vue.config.productionTip = false

const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});
export default router;

axios.interceptors.request.use(
    config => {
        const token = jwtService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['Content-Type'] = 'application/json';

        return config;
    },
    error => {
        // eslint-disable-next-line no-console
        console.error(error)
        Promise.reject(error)
    }
);

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {

        if (isRefreshing) {
            return new Promise(function(resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const credential = {
            grant_type: "refresh_token",
            refresh_token: jwtService.getRefreshToken()
        };
        return new Promise(function (resolve, reject) {
            axios.post(Const.API_HOST + '/oauth/access_token', credential)
                .then(({data}) => {
                    jwtService.setAccessToken(data.access_token);
                    jwtService.setRefreshToken(data.refresh_token)
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                    originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                    processQueue(null, data.access_token);
                    resolve(axios(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .then(() => { isRefreshing = false })
        })
    }

    return Promise.reject(error);
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
