<template>
    <section class="service-type">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">Nowy typ serwisu</h4>
                        <h4 v-else class="card-title">Edytujesz serwis <strong>{{itemName}}</strong> <code>(id: {{item.id}})</code></h4>
                        <p class="card-description pb-4">
                            Uzupełnij poniższe pola
                        </p>
                        <div class="forms-sample">
                            <b-form-group label="Nazwa" label-for="name-input">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">PL:</div>
                                    <div class="col"><b-form-input type="text" id="name-input" placeholder="Nazwa (PL)" v-model="item.name" :state="nameState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">DE:</div>
                                    <div class="col"><b-form-input type="text" id="nameDe-input" placeholder="Nazwa (DE)" v-model="item.nameDe" :state="nameDeState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">DE:</div>
                                    <div class="col"><b-form-input type="text" id="nameLt-input" placeholder="Nazwa (LT)" v-model="item.nameLt" :state="nameLtState"/></div>
                                </div>
                            </b-form-group>
                            <b-form-group label="Kolejność" label-for="order-input">
                                <b-form-input type="number" id="order-input" placeholder="Kolejność" v-model="item.itemOrder" :state="orderState" min="1"/>
                            </b-form-group>

                            <b-button v-if="$attrs.mode === 'new'" variant="success" class="mr-2" @click="saveItem" :disabled="validated">Zapisz</b-button>
                            <b-button v-else type="submit" variant="success" class="mr-2" v-b-modal.confirm-save :disabled="validated">Zapisz</b-button>
                            <b-button variant="light" v-on:click="cancel">Anuluj</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>Usuń</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-save" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Zapisz" @ok="saveItem">
            <p class="text-center">Twoja zmiana będzie miała duży wpływ na działanie i wygląd sklepu. Jeżeli nie jesteś
                jej pewien, zrezygnuj z tej operacji. W przeciwnym wypadku zatwierdź zmianę przyciskiem
                <strong>Zapisz</strong></p>
        </b-modal>
        <b-modal id="confirm-delete" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Usuń" @ok="deleteItem">
            <p class="text-center">Twoja zmiana będzie miała ogromny wpływ na działanie i wygląd sklepu. Wraz z typem
                serwisu usunięte zostaną wszystkie cenniki w których ten serwis jest ustawiony. Jeżeli nie jesteś jej
                pewien, zrezygnuj z tej operacji. W przeciwnym wypadku zatwierdź zmianę przyciskiem
                <strong>Usuń</strong></p>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'ServiceType',
        computed: {
            nameState() {
                if (this.item.name) {
                    return this.item.name.length > 0
                } else {
                    return false
                }
            },
            nameDeState() {
                if (this.item.nameDe) {
                    return this.item.nameDe.length > 0
                } else {
                    return false
                }
            },
            nameLtState() {
                if (this.item.nameLt) {
                    return this.item.nameLt.length > 0
                } else {
                    return false
                }
            },
            orderState() {
                if (this.item.itemOrder) {
                    return this.item.itemOrder > 0
                } else {
                    return false
                }
            },
            validated() {
                return !this.nameState || !this.nameDeState || !this.nameLtState || !this.orderState
            }
        },
        data() {
            return {
                item: {},
                itemName: ''
            }
        },
        mounted() {
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/serviceType/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.itemName = JSON.parse(JSON.stringify(this.item.name))
                })
            } else {
                this.item = {
                    name: '',
                    nameDe: '',
                    nameLt: '',
                    itemOrder: ''
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/service-types')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/serviceType/save', {
                    id: this.item.id,
                    name: this.item.name,
                    nameDe: this.item.nameDe,
                    nameLt: this.item.nameLt,
                    itemOrder: this.item.itemOrder
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/service-types')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/serviceType/delete', {
                    data: {
                        id: this.item.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/service-types')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

</style>
