<template lang="html">

  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item nav-profile">
          <div class="nav-link">
            <div class="profile-image"> <img src="../../assets/images/mt-generic-user.png" alt="image"/> <span class="online-status online"></span> </div>
            <div class="profile-name">
              <p class="name">{{ username }}</p>
              <p class="designation">{{ role }}</p>
              <div class="badge badge-teal mx-auto mt-3">Online</div>
            </div>
          </div>
        </li>
        <li class="nav-item" v-if="this.mainRole == 'ROLE_ADMIN'">
            <router-link class="nav-link" to="/"><img class="menu-icon" src="../../assets/images/menu_icons/dashboard-icon.png" alt="menu icon"><span class="menu-title">Dashboard</span></router-link>
        </li>
        <li class="nav-item" v-if="this.mainRole == 'ROLE_ADMIN' || this.mainRole == 'ROLE_ORDERS'">
            <router-link class="nav-link" to="/paid-order-requests/"><img class="menu-icon" src="../../assets/images/menu_icons/paid-order-requests-icon.png" alt="menu icon"><span class="menu-title">Zamówienia</span></router-link>
        </li>
        <li class="nav-item" v-if="this.mainRole == 'ROLE_ADMIN' || this.mainRole == 'ROLE_ORDERS'">
            <router-link class="nav-link" to="/order-requests/"><img class="menu-icon" src="../../assets/images/menu_icons/order-requests-icon.png" alt="menu icon"><span class="menu-title">Zapytania o wycenę</span></router-link>
        </li>
        <li class="nav-item" v-if="this.mainRole == 'ROLE_ADMIN'">
          <span class="nav-link" v-b-toggle="'shop-items'">
            <img class="menu-icon" src="../../assets/images/menu_icons/shopping-cart-icon.png" alt="menu icon"><span class="menu-title">Sklep</span><i class="menu-arrow"></i>
          </span>
          <b-collapse id="shop-items">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item"><router-link class="nav-link" to="/product-categories">Kategorie produktu</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/service-types">Typy serwisu</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/products">Produkty</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/prices">Ceny</router-link></li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item" v-if="this.mainRole == 'ROLE_ADMIN'">
          <span class="nav-link" v-b-toggle="'cms-items'">
            <img class="menu-icon" src="../../assets/images/menu_icons/cms-icon.png" alt="menu icon"><span class="menu-title">CMS</span><i class="menu-arrow"></i>
          </span>
          <b-collapse id="cms-items">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item"><router-link class="nav-link" to="/pages/">Strony</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/fragments/">Fragmenty stron</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/infos/">Aktualności</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/departments/">Oddziały</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/faq-categories/">FAQ - kategorie</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/faqs/">FAQ - wpisy</router-link></li>
              <!--<li class="nav-item"><router-link class="nav-link" to="/main-carousel/">Karuzela</router-link></li> TODO: implementacja CRUD
              <li class="nav-item"><router-link class="nav-link" to="/offer-carousel/">Oferta - kategorie</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/about-item/">O firmie - wpis</router-link></li>-->
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item" v-if="this.mainRole == 'ROLE_ADMIN'">
            <router-link class="nav-link" to="/emails/"><img class="menu-icon" src="../../assets/images/menu_icons/email-icon.png" alt="menu icon"><span class="menu-title">Email</span></router-link>
        </li>
      </ul>
    </nav>
  </section>

</template>

<script lang="js">
import jwtService from "../../mixins/JwtStorageService";

export default {
  name: 'app-sidebar',
  data() {
    return {
      username: '',
      role: '',
      mainRole: '',
    }
  },
  mounted() {
    this.username = jwtService.getUsername()
    if (jwtService.getRoles().includes("ROLE_ADMIN")) {
      this.role = "Administrator"
      this.mainRole = "ROLE_ADMIN"
    } else if (jwtService.getRoles().includes("ROLE_ORDERS")) {
      this.role = "Zamówienia - odczyt"
      this.mainRole = "ROLE_ORDERS"
    }
  }
}
</script>

<style scoped lang="scss">
.app-sidebar {

}
</style>
