<template>
    <section class="product-category">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">Nowa kategoria produktu</h4>
                        <h4 v-else class="card-title">Edytujesz kategorię <strong>{{itemName}}</strong> <code>(id: {{item.id}})</code></h4>
                        <p class="card-description pb-4">
                            Uzupełnij poniższe pola
                        </p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-4">
                                    <b-form-group label="Nazwa kategorii" label-for="name-input">
                                        <b-form-input type="text" id="name-input" placeholder="Tytuł" v-model="item.name" :state="nameState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-form-group label="Tytuł strony" label-for="title-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="title-input" placeholder="Tytuł strony (PL)" v-model="item.title" :state="titleState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="titleDe-input" placeholder="Tytuł strony (DE)" v-model="item.titleDe" :state="titleDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="titleLt-input" placeholder="Tytuł strony (LT)" v-model="item.titleLt" :state="titleLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group label="Kolejność" label-for="order-input">
                                        <b-form-input type="number" id="order-input" placeholder="Kolejność" v-model="item.itemOrder" :state="orderState" min="1"/>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group label="Opis strony" label-for="description-input">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">PL:</div>
                                    <div class="col"><b-form-input type="text" id="description-input" placeholder="Opis (PL)" v-model="item.description" :state="descriptionState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">DE:</div>
                                    <div class="col"><b-form-input type="text" id="descriptionDe-input" placeholder="Opis (DE)" v-model="item.descriptionDe" :state="descriptionDeState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">LT:</div>
                                    <div class="col"><b-form-input type="text" id="descriptionLt-input" placeholder="Opis (LT)" v-model="item.descriptionLt" :state="descriptionLtState"/></div>
                                </div>
                            </b-form-group>

                            <b-button v-if="$attrs.mode === 'new'" variant="success" class="mr-2" @click="saveItem" :disabled="validated">Zapisz</b-button>
                            <b-button v-else type="submit" variant="success" class="mr-2" v-b-modal.confirm-save :disabled="validated">Zapisz</b-button>
                            <b-button variant="light" v-on:click="cancel">Anuluj</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>Usuń</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-save" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Zapisz" @ok="saveItem">
            <p class="text-center">Twoja zmiana będzie miała ogromny wpływ na działanie i wygląd strony. Jeżeli nie
                jesteś jej pewien, zrezygnuj z tej operacji. W przeciwnym wypadku zatwierdź zmianę przyciskiem
                <strong>Zapisz</strong></p>
        </b-modal>
        <b-modal id="confirm-delete" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Usuń" @ok="deleteItem">
            <p class="text-center">Twoja zmiana będzie miała ogromny wpływ na działanie i wygląd strony. Wraz z
                kategorią produktów usunięte zostaną wszystkie produkty z tej kategorii oraz cenniki dla tych produktów.
                Jeżeli nie jesteś jej pewien, zrezygnuj z tej operacji. W przeciwnym wypadku zatwierdź zmianę przyciskiem
                <strong>Usuń</strong></p>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'ProductCategory',
        computed: {
            nameState() {
                if (this.item.name) {
                    return this.item.name.length > 0
                } else {
                    return false
                }
            },
            orderState() {
                if (this.item.itemOrder) {
                    return this.item.itemOrder > 0
                } else {
                    return false
                }
            },
            titleState() {
                if (this.item.title) {
                    return this.item.title.length > 0
                } else {
                    return false
                }
            },
            titleDeState() {
                if (this.item.titleDe) {
                    return this.item.titleDe.length > 0
                } else {
                    return false
                }
            },
            titleLtState() {
                if (this.item.titleLt) {
                    return this.item.titleLt.length > 0
                } else {
                    return false
                }
            },
            descriptionState() {
                if (this.item.description) {
                    return this.item.description.length > 0
                } else {
                    return false
                }
            },
            descriptionDeState() {
                if (this.item.descriptionDe) {
                    return this.item.descriptionDe.length > 0
                } else {
                    return false
                }
            },
            descriptionLtState() {
                if (this.item.descriptionLt) {
                    return this.item.descriptionLt.length > 0
                } else {
                    return false
                }
            },
            validated() {
                return !this.nameState || !this.orderState || !this.titleState || !this.titleDeState ||
                    !this.titleLtState || !this.descriptionState || !this.descriptionDeState || !this.descriptionLtState
            }
        },
        data() {
            return {
                item: {},
                itemName: ''
            }
        },
        mounted() {
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/productCategory/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.itemName = JSON.parse(JSON.stringify(this.item.name))
                })
            } else {
                this.item = {
                    name: '',
                    itemOrder: '',
                    title: '',
                    titleDe: '',
                    titleLt: '',
                    description: '',
                    descriptionDe: '',
                    descriptionLt: '',
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/product-categories')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/productCategory/save', {
                    id: this.item.id,
                    name: this.item.name,
                    itemOrder: this.item.itemOrder,
                    title: this.item.title,
                    titleDe: this.item.titleDe,
                    titleLt: this.item.titleLt,
                    description: this.item.description,
                    descriptionDe: this.item.descriptionDe,
                    descriptionLt: this.item.descriptionLt,
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/product-categories')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/productCategory/delete', {
                    data: {
                        id: this.item.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/product-categories')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

</style>
