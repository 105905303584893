<template>
    <section class="tables">
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title">Serwis</h2>
                        <p class="card-description">
                            Typy serwisów (częstotliwości) dostępne do wybrania w definicji produktu
                        </p>
                        <b-button variant="primary" class="mt-4 mb-4" @click="$router.push('/service-type/new')">Nowy typ serwisu</b-button>
                        <b-table striped hover :items="items" :fields="fields" @row-clicked="editItem" class="clickable"></b-table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'ServiceTypeList',
        data() {
            return {
                items: [],
                fields: [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true,
                        thStyle: 'width: 75px;'
                    },
                    {
                        key: 'name',
                        label: 'Typ serwisu',
                        sortable: false
                    },
                    {
                        key: 'itemOrder',
                        label: 'Kolejność wyświetlania',
                        sortable: true,
                        thStyle: 'width: 220px;'
                    }
                ]
            }
        },
        mounted() {
            axios.get(Const.API_HOST + '/admin/serviceTypes')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.items = body
            })
        },
        methods: {
            editItem(item) {
                // eslint-disable-next-line no-console
                console.log(item)
                this.$router.push('/service-type/edit/' + item.id)
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

</style>
