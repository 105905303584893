<template>
    <section class="page-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">Nowa strona</h4>
                        <h4 v-else class="card-title">Edytujesz stronę <strong>{{pageName}}</strong> <code>(id:
                            {{page.id}})</code></h4>
                        <p class="card-description pb-4">
                            Uzupełnij poniższe pola
                        </p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group label="Nazwa" label-for="name-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="name-input" placeholder="Nazwa (PL)" v-model="page.name" :state="nameState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="nameDe-input" placeholder="Nazwa (DE)" v-model="page.nameDe" :state="nameDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="nameDe-input" placeholder="Nazwa (LT)" v-model="page.nameLt" :state="nameLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-form-group label="Tytuł strony" label-for="title-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="title-input" placeholder="Tytuł (PL)" v-model="page.title" :state="titleState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="titleDe-input" placeholder="Tytuł (DE)" v-model="page.titleDe" :state="titleDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="titleLt-input" placeholder="Tytuł (LT)" v-model="page.titleLt" :state="titleLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group label="Słowa kluczowe" label-for="order-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="keywords-input" placeholder="Słowa kluczowe (PL)" v-model="page.keywords" :state="keywordsState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="keywordsDe-input" placeholder="Słowa kluczowe (DE)" v-model="page.keywordsDe" :state="keywordsDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="keywordsLt-input" placeholder="Słowa kluczowe (LT)" v-model="page.keywordsLt" :state="keywordsLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-form-group label="URL strony" label-for="url-input">
                                        <b-form-input type="text" id="url-input" placeholder="URL" v-model="page.url" :state="urlState"/>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group label="Opis strony" label-for="description-input">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">PL:</div>
                                    <div class="col"><b-form-input type="text" id="description-input" placeholder="Opis (PL)" v-model="page.description" :state="descriptionState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">DE:</div>
                                    <div class="col"><b-form-input type="text" id="descriptionDe-input" placeholder="Opis (DE)" v-model="page.descriptionDe" :state="descriptionDeState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">LT:</div>
                                    <div class="col"><b-form-input type="text" id="descriptionLt-input" placeholder="Opis (LT)" v-model="page.descriptionLt" :state="descriptionLtState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group label="Główny obraz" label-for="image-input">
                                <b-button id="image-input" :variant="page.image && page.image.id ? 'outline-success' : 'outline-danger'" @click="openImagePicker('image-input')">
                                    {{page.image && page.image.id ? page.image.filename.substr(43) : 'Wybierz'}}
                                </b-button>
                                <b-popover v-if="page.image && page.image.id" target="image-input" triggers="hover" placement="right">
                                    <p class="m-0 p-0"><img :src="page.image.filename" class="browser-image"></p>
                                    <p class="m-0 p-0 text-center">Rozmiar: {{page.image.width}} x
                                        {{page.image.height}}</p>
                                </b-popover>
                            </b-form-group>

                            <b-form-group label="Zawartość strony (PL)" label-for="tinymce-input">
                                <editor ref="formEditor" id="tinymce-input" :api-key="editorApiKey" :init="editorConf" v-model="page.content"/>
                            </b-form-group>

                            <b-form-group label="Zawartość strony (DE)" label-for="tinymce-inputDe">
                                <editor ref="formEditorDe" id="tinymce-inputDe" :api-key="editorApiKey" :init="editorConfDe" v-model="page.contentDe"/>
                            </b-form-group>

                            <b-form-group label="Zawartość strony (LT)" label-for="tinymce-inputLt">
                                <editor ref="formEditorLt" id="tinymce-inputLt" :api-key="editorApiKey" :init="editorConfLt" v-model="page.contentLt"/>
                            </b-form-group>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">Zapisz</b-button>
                            <b-button variant="light" v-on:click="cancel">Anuluj</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>Usuń</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Usuń" @ok="deleteItem">
            <p class="text-center">Potwierdź operację</p>
        </b-modal>

        <b-modal id="image-picker" title="Wstaw obraz" size="xl" header-bg-variant="primary" header-text-variant="light" okTitle="Zamknij" ok-only>
            <div class="row images">
                <div class="col-lg-1 my-auto text-center p-1" v-for="image in images" :key="image.id" :id="'image-wrapper-' + image.id">
                    <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image" @click="pasteImage(image.id)">
                    <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                        <div class="row">
                            <div class="col text-center">
                                <span><em>{{image.filename.substr(43)}}</em></span><br>
                                <span class="p-0">{{image.width + ' x ' + image.height}}</span>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    var Editor = require('@tinymce/tinymce-vue').default;
    var app = null

    export default {
        name: 'Page',
        components: {
            'editor': Editor
        },
        computed: {
            nameState() {
                if (this.page.name) {
                    return this.page.name.length > 0
                } else {
                    return false
                }
            },
            nameDeState() {
                if (this.page.nameDe) {
                    return this.page.nameDe.length > 0
                } else {
                    return false
                }
            },
            nameLtState() {
                if (this.page.nameLt) {
                    return this.page.nameLt.length > 0
                } else {
                    return false
                }
            },
            titleState() {
                if (this.page.title) {
                    return this.page.title.length > 0
                } else {
                    return false
                }
            },
            titleDeState() {
                if (this.page.titleDe) {
                    return this.page.titleDe.length > 0
                } else {
                    return false
                }
            },
            titleLtState() {
                if (this.page.titleLt) {
                    return this.page.titleLt.length > 0
                } else {
                    return false
                }
            },
            descriptionState() {
                if (this.page.description) {
                    return this.page.description.length > 0
                } else {
                    return false
                }
            },
            descriptionDeState() {
                if (this.page.descriptionDe) {
                    return this.page.descriptionDe.length > 0
                } else {
                    return false
                }
            },
            descriptionLtState() {
                if (this.page.descriptionLt) {
                    return this.page.descriptionLt.length > 0
                } else {
                    return false
                }
            },
            keywordsState() {
                if (this.page.keywords) {
                    return this.page.keywords.length > 0
                } else {
                    return false
                }
            },
            keywordsDeState() {
                if (this.page.keywordsDe) {
                    return this.page.keywordsDe.length > 0
                } else {
                    return false
                }
            },
            keywordsLtState() {
                if (this.page.keywordsLt) {
                    return this.page.keywordsLt.length > 0
                } else {
                    return false
                }
            },
            urlState() {
                if (this.page.url) {
                    return this.page.url.length > 0 &&
                        this.page.url.startsWith('/') &&
                        this.page.url.split('/').length === 2
                } else {
                    return false
                }
            },
            imageState() {
                return this.page.image && this.page.image.id > 0
            },
            validated() {
                return !this.nameState || !this.nameDeState || !this.nameLtState || !this.titleState ||
                    !this.titleDeState || !this.titleLtState || !this.descriptionState || !this.descriptionDeState ||
                    !this.descriptionLtState || !this.urlState || !this.keywordsState || !this.keywordsDeState ||
                    !this.keywordsLtState || !this.imageState
            }
        },
        data() {
            return {
                images: [],
                page: {},
                pageName: '',
                editorApiKey: "jdsjwlv71ngv4ngm4fca30grz3lu6w833qma1br216ewfsqh",
                editorConf: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-input')
                        });
                    }
                },
                editorConfDe: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-inputDe')
                        });
                    }
                },
                editorConfLt: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-inputLt')
                        });
                    }
                },
                imagePickerContext: ''
            }
        },
        mounted() {
            app = this
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/page/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.page = body
                    this.pageName = JSON.parse(JSON.stringify(this.page.name))
                })
            } else {
                this.page = {
                    name: '',
                    nameDe: '',
                    nameLt: '',
                    title: '',
                    titleDe: '',
                    titleLt: '',
                    description: '',
                    descriptionDe: '',
                    descriptionLt: '',
                    keywords: '',
                    keywordsDe: '',
                    keywordsLt: '',
                    image: {},
                    url: '',
                    content: '',
                    contentDe: '',
                    contentLt: ''
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/pages')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/page/save', {
                    page: {
                        id: this.page.id,
                        name: this.page.name,
                        nameDe: this.page.nameDe,
                        nameLt: this.page.nameLt,
                        title: this.page.title,
                        titleDe: this.page.titleDe,
                        titleLt: this.page.titleLt,
                        description: this.page.description,
                        descriptionDe: this.page.descriptionDe,
                        descriptionLt: this.page.descriptionLt,
                        keywords: this.page.keywords,
                        keywordsDe: this.page.keywordsDe,
                        keywordsLt: this.page.keywordsLt,
                        image: this.page.image,
                        content: this.page.content,
                        contentDe: this.page.contentDe,
                        contentLt: this.page.contentLt,
                        url: this.page.url
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/pages')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/page/delete', {
                    data: {
                        id: this.page.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/pages')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            openImagePicker(context) {
                this.imagePickerContext = context
                this.$bvModal.show('image-picker')
                axios.get(Const.API_HOST + '/admin/images')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.images = body
                })
            },
            pasteImage(id) {
                var selected = this.images.find(obj => {
                    return obj.id === id
                })

                // eslint-disable-next-line no-console
                console.log(selected.filename)
                var html = '<p class="par-with-images"><img src="' + selected.filename + '" alt="' + selected.alt + '" width="' + selected.width + 'px" height="' + selected.height + 'px"></p>'

                if (this.imagePickerContext === 'tinymce-input') {
                    this.$refs.formEditor.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'tinymce-inputDe') {
                    this.$refs.formEditorDe.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'tinymce-inputLt') {
                    this.$refs.formEditorLt.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'image-input') {
                    this.page.image = JSON.parse(JSON.stringify(selected))
                }

                this.$bvModal.hide('image-picker')
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

    .browser-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    /deep/ .tox-editor-container div:first-child {
        top: 58px !important;
    }
</style>
