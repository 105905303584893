<template>
    <section class="product-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">Nowy produkt</h4>
                        <h4 v-else class="card-title">Edytujesz produkt <strong>{{itemName}}</strong> <code>(id: {{item.id}})</code></h4>
                        <p class="card-description pb-4">
                            Uzupełnij poniższe pola
                        </p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group label="Nazwa" label-for="name-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="name-input" placeholder="Nazwa (PL)" v-model="item.name" :state="nameState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="nameDe-input" placeholder="Nazwa (DE)" v-model="item.nameDe" :state="nameDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="nameLt-input" placeholder="Nazwa (LT)" v-model="item.nameLt" :state="nameLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group label="Kategoria produktu" label-for="category-input">
                                        <b-form-select v-model="item.category.id" :options="categories" value-field="id" text-field="name" :state="categoryState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2 my-auto">
                                    <b-form-group label="Możliwość zakupu" label-for="category-input">
                                        <b-form-checkbox v-model="item.canBuy" name="check-button" switch size="lg">
                                            <p v-text="item.canBuy ? 'tak' : 'nie'" style="margin-top: 4px;" class="pl-2" />
                                        </b-form-checkbox>
                                    </b-form-group>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group label="Tytuł strony produktu" label-for="title-input">
                                        <div class="row align-items-center">
                                            <div class="col-auto text-right min-45px-w">PL:</div>
                                            <div class="col"><b-form-input type="text" id="title-input" placeholder="Tytuł (PL)" v-model="item.title" :state="titleState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">DE:</div>
                                            <div class="col"><b-form-input type="text" id="titleDe-input" placeholder="Tytuł (DE)" v-model="item.titleDe" :state="titleDeState"/></div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-auto text-right min-45px-w">LT:</div>
                                            <div class="col"><b-form-input type="text" id="titleLt-input" placeholder="Tytuł (LT)" v-model="item.titleLt" :state="titleLtState"/></div>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group label="URL strony produktu" label-for="url-input">
                                        <b-form-input type="text" id="url-input" placeholder="URL" v-model="item.url" :state="urlState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group label="Kolejność" label-for="order-input">
                                        <b-form-input type="number" id="order-input" placeholder="Kolejność" v-model="item.itemOrder" :state="orderState" min="1"/>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group label="Opis strony produktu" label-for="description-input">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">PL:</div>
                                    <div class="col"><b-form-input type="text" id="description-input" placeholder="Opis (PL)" v-model="item.description" :state="descriptionState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">DE:</div>
                                    <div class="col"><b-form-input type="text" id="description-input" placeholder="Opis (DE)" v-model="item.descriptionDe" :state="descriptionDeState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">LT:</div>
                                    <div class="col"><b-form-input type="text" id="description-input" placeholder="Opis (LT)" v-model="item.descriptionLt" :state="descriptionLtState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group label="Najważniejsze cechy produktu" label-for="features-input">
                                <b-form-tags id="features-input" v-model="itemFeatures" @input="resetInputValue()" tag-variant="success" class="mb-2 mt-2"
                                             no-outer-focus placeholder="Wpisz cechę produktu (PL)|Wpisz cechę produktu (DE)|Wpisz cechę produktu (LT)" :state="allFeaturesState">
                                    <template v-slot="{tags, inputId, placeholder, disabled, addTag, removeTag }">
                                        <b-input-group>
                                            <b-form-input v-model="newTag" :id="inputId" :placeholder="placeholder.substring(0, placeholder.indexOf('|'))"></b-form-input>
                                            <b-form-input v-model="newTagDe" :id="inputId + 'De'" :placeholder="placeholder.substring(placeholder.indexOf('|') + 1, placeholder.lastIndexOf('|'))"></b-form-input>
                                            <b-form-input v-model="newTagLt" :id="inputId + 'Lt'" :placeholder="placeholder.substring(placeholder.lastIndexOf('|') + 1)"></b-form-input>
                                            <b-input-group-append>
                                                <b-button @click="addTag('PL: ' + newTag + ' | DE: ' + newTagDe + ' | LT: ' + newTagLt)" variant="primary" class="input-button">Dodaj</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <b-form-invalid-feedback :state="featureState">
                                            Taka cecha już istnieje!
                                        </b-form-invalid-feedback>

                                        <ul v-if="tags.length > 0" class="mb-0 pl-0">
                                            <li v-for="tag in tags" :key="tag" :title="`Cecha: ${tag}`" class="mt-2 list-unstyled">
                                                <span class="d-flex align-items-center">
                                                  <b-button size="sm" variant="outline-danger" @click="removeTag(tag)">usuń</b-button>
                                                  <span class="mr-2 pl-2">{{ tag }}</span>
                                                </span>
                                            </li>
                                        </ul>
                                        <b-form-text v-else>
                                            Nie została dodana żadna cecha produktu
                                        </b-form-text>
                                    </template>
                                </b-form-tags>
                            </b-form-group>

                            <div v-if="newAS != null" class="row m-0 p-0 mt-4 mb-1">
                                <p>Usługi dodatkowe</p>
                                <b-table responsive hover :items="newAS" :fields="asFields" small class="pt-0 mt-0 pb-0 mb-0">
                                    <template v-slot:cell(name)="row">
                                        <b-form-input :state="nameInputValidation(row.index)" v-model="row.item.name"/>
                                    </template>
                                    <template v-slot:cell(nameDe)="row" >
                                        <b-form-input :state="nameDeInputValidation(row.index)" v-model="row.item.nameDe"/>
                                    </template>
                                    <template v-slot:cell(nameLt)="row" >
                                        <b-form-input :state="nameLtInputValidation(row.index)" v-model="row.item.nameLt"/>
                                    </template>
                                    <template v-slot:cell(grossPrice)="row">
                                        <b-form-input :state="grossPriceValidation(row.index)" v-model="row.item.grossPrice" type="number"/>
                                    </template>
                                    <template v-slot:cell(grossPriceEur)="row">
                                        <b-form-input :state="grossPriceEurValidation(row.index)" v-model="row.item.grossPriceEur" type="number"/>
                                    </template>
                                    <template v-slot:cell(serviceType)="row">
                                        <b-form-select v-model="row.item.serviceType" :options="options"/>
                                    </template>
                                    <template v-slot:cell(serviceOrder)="row">
                                        <b-form-input :state="serviceOrderValidation(row.index)" v-model="row.item.serviceOrder" type="number"/>
                                    </template>
                                    <template v-slot:cell(delete)="row">
                                        <b-button variant="danger" class="m-1 p-1"
                                                @click="deleteAdditionalServiceRow(row.index)">Usuń</b-button>
                                    </template>
                                </b-table>
                            </div>
                            <div class="mt-1 mr-2 mb-4 pt-0">
                                <b-button variant="primary" class="mr-2 float-right"
                                        @click="addAdditionalServiceRow">Dodaj usługę</b-button>
                            </div>
                            <div class="row mt-12 pt-5">
                                <div class="col-lg-4">
                                    <b-form-group label="Miniatura" label-for="thumbnail-input">
                                        <b-button id="thumbnail-input" :variant="item.thumbnail && item.thumbnail.id ? 'outline-success' : 'outline-danger'" @click="openImagePicker('thumbnail-input')" :state="thumbnailState">
                                            {{item.thumbnail && item.thumbnail.id ? item.thumbnail.filename.substr(43) : 'Wybierz'}}
                                        </b-button>
                                        <b-popover v-if="item.thumbnail && item.thumbnail.id" target="thumbnail-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="item.thumbnail.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">Rozmiar: {{item.thumbnail.width}} x {{item.thumbnail.height}}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group label="Główny obraz" label-for="image-input">
                                        <b-button id="image-input" :variant="item.image && item.image.id ? 'outline-success' : 'outline-danger'" @click="openImagePicker('image-input')">
                                            {{item.image && item.image.id ? item.image.filename.substr(43) : 'Wybierz'}}
                                        </b-button>
                                        <b-popover v-if="item.image && item.image.id" target="image-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="item.image.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">Rozmiar: {{item.image.width}} x {{item.image.height}}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group label="Zdjęcie 360°" label-for="image360-input">
                                        <b-button id="image360-input" :variant="item.image360 && item.image360.id ? 'outline-success' : 'outline-primary'" @click="openImagePicker('image360-input')">
                                            {{item.image360 && item.image360.id ? item.image360.filename.substr(43) : 'Wybierz'}}
                                        </b-button>
                                        <b-popover v-if="item.image360 && item.image360.id" target="image360-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="item.image360.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">Rozmiar: {{item.image360.width}} x {{item.image360.height}}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group label="Karta produktu" label-for="datasheet-input" class="pt-3">
                                <div class="row align-items-center pl-2">
                                    <b-button id="datasheet-input" :variant="item.datasheet && item.datasheet.id ? 'outline-success' : 'outline-primary'" @click="openFilePicker('datasheet-input')">
                                        {{item.datasheet && item.datasheet.id ? 'Zamień' : 'Wybierz'}}
                                    </b-button>
                                    <div class="col"><b-form-input type="text" id="datasheet-input" placeholder="-" :value="item.datasheet && item.datasheet.filename ? item.datasheet.filename.substr(42) : ''" disabled/></div>
                                </div>
                            </b-form-group>

                            <b-form-group label="Opis produktu (PL)" label-for="summary-input" class="pt-3">
                                <editor ref="formEditor" id="summary-input" :api-key="editorApiKey" :init="editorConf" v-model="item.summary"/>
                            </b-form-group>

                            <b-form-group label="Opis produktu (DE)" label-for="summaryDe-input">
                                <editor ref="formEditorDe" id="summaryDe-input" :api-key="editorApiKey" :init="editorConfDe" v-model="item.summaryDe"/>
                            </b-form-group>

                            <b-form-group label="Opis produktu (LT)" label-for="summaryLt-input">
                                <editor ref="formEditorLt" id="summaryLt-input" :api-key="editorApiKey" :init="editorConfLt" v-model="item.summaryLt"/>
                            </b-form-group>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">Zapisz</b-button>
                            <b-button variant="light" v-on:click="cancel">Anuluj</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>Usuń</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" title="Uwaga" size="md" header-bg-variant="danger" header-text-variant="light" cancelTitle="Anuluj" okTitle="Usuń" @ok="deleteItem">
            <p class="text-center">Twoja zmiana będzie miała wpływ na widoczność niektórych produktów w sklepie. Wraz z
                produktem usunięte zostaną wszystkie cenniki dla niego zdefiniowane. Jeżeli nie jesteś jej pewien,
                zrezygnuj z tej operacji. W przeciwnym wypadku zatwierdź zmianę przyciskiem <strong>Usuń</strong></p>
        </b-modal>

        <b-modal id="image-picker" title="Wstaw obraz" size="xl" header-bg-variant="primary" header-text-variant="light" okTitle="Zamknij" ok-only>
            <div class="row images">
                <div class="col-lg-1 my-auto text-center p-1" v-for="image in images" :key="image.id" :id="'image-wrapper-' + image.id">
                    <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image" @click="pasteImage(image.id)">
                    <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                        <div class="row">
                            <div class="col text-center">
                                <span><em>{{image.filename.substr(43)}}</em></span><br>
                                <span class="p-0">{{image.width + ' x ' + image.height}}</span>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>
        </b-modal>
        <b-modal id="file-picker" title="Wybierz plik" size="xl" header-bg-variant="primary" header-text-variant="light" okTitle="Zamknij" ok-only>
            <b-table striped hover small :items="files" :fields="fileFields" @row-clicked="pasteFile" class="clickable"></b-table>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    import moment from "moment/moment";
    var Editor = require('@tinymce/tinymce-vue').default;
    var app = null

    export default {
        name: 'ProductItem',
        components: {
            'editor': Editor
        },
        computed: {
            nameState() {
                if (this.item.name) {
                    return this.item.name.length > 0
                } else {
                    return false
                }
            },
            nameDeState() {
                if (this.item.nameDe) {
                    return this.item.nameDe.length > 0
                } else {
                    return false
                }
            },
            nameLtState() {
                if (this.item.nameLt) {
                    return this.item.nameLt.length > 0
                } else {
                    return false
                }
            },
            titleState() {
                if (this.item.title) {
                    return this.item.title.length > 0
                } else {
                    return false
                }
            },
            titleDeState() {
                if (this.item.titleDe) {
                    return this.item.titleDe.length > 0
                } else {
                    return false
                }
            },
            titleLtState() {
                if (this.item.titleLt) {
                    return this.item.titleLt.length > 0
                } else {
                    return false
                }
            },
            descriptionState() {
                if (this.item.description) {
                    return this.item.description.length > 0
                } else {
                    return false
                }
            },
            descriptionDeState() {
                if (this.item.descriptionDe) {
                    return this.item.descriptionDe.length > 0
                } else {
                    return false
                }
            },
            descriptionLtState() {
                if (this.item.descriptionLt) {
                    return this.item.descriptionLt.length > 0
                } else {
                    return false
                }
            },
            urlState() {
                if (this.item.url) {
                    return this.item.url.length > 0 &&
                        this.item.url.startsWith('/') &&
                        this.item.url.split('/').length === 2
                } else {
                    return false
                }
            },
            orderState() {
                if (this.item.itemOrder) {
                    return this.item.itemOrder > 0
                } else {
                    return false
                }
            },
            categoryState() {
                if (this.item.category) {
                    return this.item.category.id > 0
                } else {
                    return false
                }
            },
            featureState() {
                return this.item.features.indexOf(this.newTag.trim()) > -1 ? false : null
            },
            allFeaturesState() {
                return this.itemFeatures.length > 0
            },
            thumbnailState() {
                return this.item.thumbnail && this.item.thumbnail.id > 0
            },
            imageState() {
                return this.item.image && this.item.image.id > 0
            },
            image360State() {
                return this.item.image360 && this.item.image360.id > 0
            },
            validateAdditionalServices() {
                if (this.newAS == null) {
                    return true;
                }
                for (let i = 0; i < this.newAS.length; i++) {
                    if (this.newAS[i].name === undefined
                        || this.newAS[i].name.length < 5
                        || this.newAS[i].nameDe === undefined
                        || this.newAS[i].nameDe.length < 5
                        || this.newAS[i].nameLt === undefined
                        || this.newAS[i].nameLt.length < 5
                        || !(this.newAS[i].grossPrice >= 0)
                        || !(this.newAS[i].grossPriceEur >= 0)
                        || !(this.newAS[i].serviceOrder > 0)) {
                        return false;
                    }
                }
                return true;
            },
            validated() {
                // eslint-disable-next-line no-console
                console.log(this.nameState)
                // eslint-disable-next-line no-console
                console.log(this.nameDeState)
                // eslint-disable-next-line no-console
                console.log(this.nameLtState)
                // eslint-disable-next-line no-console
                console.log(this.titleState)
                // eslint-disable-next-line no-console
                console.log(this.titleDeState)
                // eslint-disable-next-line no-console
                console.log(this.titleLtState)
                // eslint-disable-next-line no-console
                console.log(this.descriptionState)
                // eslint-disable-next-line no-console
                console.log(this.descriptionDeState)
                // eslint-disable-next-line no-console
                console.log(this.descriptionLtState)
                // eslint-disable-next-line no-console
                console.log(this.urlState)
                // eslint-disable-next-line no-console
                console.log(this.categoryState)
                // eslint-disable-next-line no-console
                console.log(this.orderState)
                // eslint-disable-next-line no-console
                console.log(this.allFeaturesState)
                // eslint-disable-next-line no-console
                console.log(this.thumbnailState)
                // eslint-disable-next-line no-console
                console.log(this.imageState)
                // eslint-disable-next-line no-console
                console.log(this.validateAdditionalServices)
                return !this.nameState || !this.nameDeState || !this.nameLtState || !this.titleState ||
                    !this.titleDeState || !this.titleLtState || !this.descriptionState || !this.descriptionDeState ||
                    !this.descriptionLtState || !this.urlState || !this.categoryState || !this.orderState ||
                    !this.allFeaturesState || !this.thumbnailState || !this.imageState ||
                    !this.validateAdditionalServices
            }
        },
        data() {
            return {
                categories: [],
                images: [],
                files: [],
                item: {},
                itemName: '',
                newTag: '',
                newTagDe: '',
                newTagLt: '',
                itemFeatures: [],
                editorApiKey: "jdsjwlv71ngv4ngm4fca30grz3lu6w833qma1br216ewfsqh",
                editorConf: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('summary-input')
                        });
                    }
                },
                editorConfDe: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('summaryDe-input')
                        });
                    }
                },
                editorConfLt: {
                    language: 'pl',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: 'Pisz tutaj...',
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('summaryLt-input')
                        });
                    }
                },
                imagePickerContext: '',
                filePickerContext: '',
                asFields: [
                    {
                        key: 'name',
                        label: 'Nazwa usługi dodatkowej (PL)',
                    },
                    {
                        key: 'nameDe',
                        label: 'Nazwa usługi dodatkowej (DE)',
                    },
                    {
                        key: 'nameLt',
                        label: 'Nazwa usługi dodatkowej (LT)',
                    },
                    {
                        key: 'grossPrice',
                        label: 'Cena (PLN)',
                    },
                    {
                        key: 'grossPriceEur',
                        label: 'Cena (EUR)',
                    },
                    {
                        key: 'serviceType',
                        label: 'Typ opłaty',

                    },
                    {
                        key: 'serviceOrder',
                        label: 'Kolejność',
                    },
                    {
                        key: 'delete',
                        label: '',
                    }
                ],
                newAS: [],
                options: [
                    { value: "ONE_TIME_FEE", text: 'Jednorazowa' },
                    { value: "RECURRING_FEE", text: 'Cykliczna' },
                ],
                fileFields: [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true,
                    },
                    {
                        key: 'filename',
                        label: 'Nazwa pliku',
                        sortable: true,
                        formatter: value => {
                            return this.trimEllip(value.substr(42), 120)
                        }
                    },
                    {
                        key: 'uploadDate',
                        label: 'Data dodania',
                        sortable: true,
                        formatter: value => {
                            return moment(value).format('DD.MM.YYYY, HH:mm');
                        }
                    }
                ]
            }
        },
        mounted() {
            app = this
            axios.get(Const.API_HOST + '/admin/productCategories')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.categories = body
            })
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/productItem/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.itemName = JSON.parse(JSON.stringify(this.item.name))
                    this.itemFeatures = this.item.features.map(function (feature) {
                        return 'PL: ' + feature.name + ' | DE: ' + feature.nameDe + ' | LT: ' + feature.nameLt;
                    })

                    if (this.item.additionalServices == null) {
                      this.newAS = []
                    } else {
                      this.newAS = this.item.additionalServices
                    }
                })
            } else {
                this.item = {
                    name: '',
                    nameDe: '',
                    nameLt: '',
                    itemOrder: '',
                    title: '',
                    titleDe: '',
                    titleLt: '',
                    description: '',
                    descriptionDe: '',
                    descriptionLt: '',
                    category: {},
                    image: {},
                    image360: {},
                    thumbnail: {},
                    datasheet: {},
                    features: [],
                    summary: '',
                    summaryDe: '',
                    summaryLt: '',
                    url: '',
                    canBuy: false
                }
            }
        },
        methods: {
            trimEllip(s, length) {
                return s.length > length ? s.substring(0, length) + "..." : s;
            },
            resetInputValue() {
                this.newTag = ''
                this.newTagDe = ''
                this.newTagLt = ''
            },
            cancel() {
                this.$router.push('/products')
            },
            saveItem() {
                var app = this
                this.item.features = this.itemFeatures.map(function (feature) {
                    return {
                        name: feature.substring(4, feature.indexOf('|') - 1),
                        nameDe: feature.substring(feature.indexOf('|') + 6, feature.lastIndexOf('|') - 1),
                        nameLt: feature.substring(feature.lastIndexOf('|') + 6)
                    }
                })
                axios.post(Const.API_HOST + '/admin/productItem/save', {
                    productItem: {
                        id: this.item.id,
                        name: this.item.name,
                        nameDe: this.item.nameDe,
                        nameLt: this.item.nameLt,
                        itemOrder: this.item.itemOrder,
                        title: this.item.title,
                        titleDe: this.item.titleDe,
                        titleLt: this.item.titleLt,
                        description: this.item.description,
                        descriptionDe: this.item.descriptionDe,
                        descriptionLt: this.item.descriptionLt,
                        category: this.item.category,
                        image: this.item.image,
                        image360: this.item.image360,
                        thumbnail: this.item.thumbnail,
                        datasheet: this.item.datasheet,
                        features: this.item.features,
                        summary: this.item.summary,
                        summaryDe: this.item.summaryDe,
                        summaryLt: this.item.summaryLt,
                        url: this.item.url,
                        canBuy: this.item.canBuy,
                        additionalServices: this.newAS
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/products')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/productItem/delete', {
                    data: {
                        id: this.item.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/products')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            openImagePicker(context) {
                this.imagePickerContext = context
                this.$bvModal.show('image-picker')
                axios.get(Const.API_HOST + '/admin/images')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.images = body
                })
            },
            openFilePicker(context) {
                this.filePickerContext = context
                this.$bvModal.show('file-picker')
                axios.get(Const.API_HOST + '/admin/files')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.files = body
                })
            },
            pasteImage(id) {
                var selected = this.images.find(obj => {
                    return obj.id === id
                })

                // eslint-disable-next-line no-console
                console.log(selected.filename)
                let html = '<p class="par-with-images"><img src="' + selected.filename + '" alt="' + selected.alt + '" width="' + selected.width + 'px" height="' + selected.height + 'px"></p>';

                if (this.imagePickerContext === 'summary-input') {
                    this.$refs.formEditor.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'summaryDe-input') {
                    this.$refs.formEditorDe.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'summaryLt-input') {
                    this.$refs.formEditorLt.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'thumbnail-input') {
                    this.item.thumbnail = JSON.parse(JSON.stringify(selected))
                } else if (this.imagePickerContext === 'image-input') {
                    this.item.image = JSON.parse(JSON.stringify(selected))
                } else if (this.imagePickerContext === 'image360-input') {
                    this.item.image360 = JSON.parse(JSON.stringify(selected))
                }

                this.$bvModal.hide('image-picker')
                this.images = []
            },
            pasteFile(item) {
                // eslint-disable-next-line no-console
                console.log(item)
                if (this.filePickerContext === 'datasheet-input') {
                    this.item.datasheet = JSON.parse(JSON.stringify(item))
                }

                this.$bvModal.hide('file-picker')
                this.files = []
            },
            addAdditionalServiceRow() {
                let index = this.newAS.length + 1;
                this.newAS.push({
                  "name": "",
                  "nameDe": "",
                  "nameLt": "",
                  "serviceType": "RECURRING_FEE",
                  "serviceOrder": index,
                  "grossPrice": 0,
                  "grossPriceEur": 0,
                  "selectedDefault": false })
            },
            deleteAdditionalServiceRow(index) {
                this.newAS.splice(index,1)
                for (let i = 0; i < this.newAS.length; i++) {
                    this.newAS[i].serviceOrder = i+1;
                }
            },
            nameInputValidation(index) {
                return this.newAS[index].name && this.newAS[index].name.length > 5
            },
            nameDeInputValidation(index) {
                return this.newAS[index].nameDe && this.newAS[index].nameDe.length > 5
            },
            nameLtInputValidation(index) {
                return this.newAS[index].nameLt && this.newAS[index].nameLt.length > 5
            },
            grossPriceValidation(index) {
                return this.newAS[index].grossPrice >= 0
            },
            grossPriceEurValidation(index) {
                return this.newAS[index].grossPriceEur >= 0
            },
            serviceOrderValidation(index) {
                return this.newAS[index].serviceOrder > 0
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

    .browser-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    /deep/ .tox-editor-container div:first-child {
        top: 58px !important;
    }
</style>
